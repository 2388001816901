import { api } from '../api';
import { AxiosError, AxiosResponse } from 'axios';
import {
  FederationCertificationType,
  VendorVm,
} from 'src/interfaces/interfaces';

export const getVendorsAndSubs = async () => {
  try {
    const response: AxiosResponse<VendorVm[]> = await api.get(
      `api/vendor/get`,
      {
        //header: {
        //  'Metadata': 'true'
        //}
      }
    );

    if (response.status == 200) {
      return response.data;
    } else {
      console.log(response.status + ' ' + response.data);
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const getFedCertTypes = async () => {
  try {
    const response: AxiosResponse<FederationCertificationType[]> =
      await api.get(`api/vendor/GetFedCertTypes`);

    if (response.status == 200) {
      return response.data;
    } else {
      console.log(response.status + ' ' + response.data);
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const addFedCert = async (id: number, type: number, file: File) => {
  try {
    const formData = new FormData();
    formData.append('file', file, file.name.replace(/#/g, ''));

    const response: AxiosResponse<FederationCertificationType[]> =
      await api.post(`api/vendor/AddFedCert?id=${id}&type=${type}`, formData);

    if (response.status == 200) {
      return true;
    } else {
      console.log(response.status + ' ' + response.data);
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const deleteFedCert = async (id: number) => {
  try {
    const response: AxiosResponse<FederationCertificationType[]> =
      await api.delete(`api/vendor/DeleteFedCert?id=${id}`);

    if (response.status == 200) {
      return true;
    } else {
      console.log(response.status + ' ' + response.data);
    }
  } catch {
    return undefined;
  }

  return undefined;
};
