import * as React from 'react';
import { RouteComponentProps, NavLink } from 'react-router-dom';
import * as ProjectStore from '../store/projects';
import * as UserStore from '../store/user';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import {
  ProjectVm,
  PropertyFormatType,
  ListItem,
  DateRangeFilter,
  SortInfo,
} from '../interfaces/interfaces';
import { NewProjectForm as ProjectAdd} from './ProjectAdd';
import { MessageBox } from '../components/MessageBox';
import AttachmentViewer from '../components/AttachmentViewer';
import { Loader } from '../components/Loader';
//import { Tabs, TabLink, TabContent } from '../tabs';
import { Tabs } from '../tabs/Tabs';
import { TabLink } from '../tabs/TabLink';
import { TabContent } from '../tabs/TabContent';
import { MapWithTable } from '../maps/MapWithTable';
import { MyAuthElement, NoAuthElement } from '../auth/Authorization';
import { KnownRoles } from '../auth/auth';
import { toMoney, camelize } from '../helpers/formatters';
import * as cx from 'classnames';
import { FileDownloader } from '../components/FileDownloader';
import KPI from '../charts/KPI';
import ProjectList from './ProjectList';
import { SVDateRangePicker } from '../components/SVDateRangePicker';
import { DateRange } from 'moment-range';
import { ProjectsLoader } from '../loaders/ProjectsLoader';
import { RectangleLoader } from '../loaders/RectangleLoader';
import { CMKpiReportModal } from '../components/CMKpiReportModal';

interface ProjectsState {
  attachmentProjectId?: number;
  attachmentMilestoneId?: number;
  attachmentProjectNo?: string;
  attachmentClientName?: string;
  showCMKpiReportModal: boolean;
  showNewProjectForm: boolean;
  showFiltersRow: boolean;
  showComplete: boolean;
  editingColumns: boolean;
  globalFilterText: string;
  sortInfo: SortInfo;
}

interface JoinedProps {
  p: ProjectStore.Store;
  u: UserStore.Store;
}

type ProjectsProps = RouteComponentProps<any> &
  JoinedProps &
  typeof ProjectStore.actionCreators;

class ProjectsView extends React.Component<ProjectsProps, ProjectsState> {
  constructor(props: ProjectsProps) {
    super(props);
    this.state = {
      showCMKpiReportModal: false,
      showNewProjectForm: false,
      showFiltersRow: false,
      showComplete: false,
      editingColumns: false,
      globalFilterText: '',
      sortInfo: { sortProp: '', sortAsc: false },
    };
  }

  componentDidMount() {
    this.props.getProjects();
    this.props.getAllProjectColumns();
    this.props.getUserColumns();
  }

  componentWillUpdate(nextProps: ProjectsProps) {
    if (nextProps.p.projects.length > this.props.p.projects.length) {
      this.setState({
        showNewProjectForm: false,
      });
    }
  }

  shouldComponentUpdate() {
    if (this.props.p.projects) return true;
    return false;
  }

  componentDidUpdate() {
    if (this.props.p.projects) {
      this.fixHeaderWidths();
    }
  }

  componentWillUnmount() {
    this.props.clearMessage();
  }

  pmComponent = MyAuthElement([
    KnownRoles.Admin,
    KnownRoles.CSGPM,
    KnownRoles.ClientAdmin,
    KnownRoles.ClientPM,
    KnownRoles.CoreSuperUser,
  ]);
  noVendorComponent = NoAuthElement([KnownRoles.Vendor]);

  fixHeaderWidths = () => {
    const containers = document.querySelectorAll('.project-list-container');
    [].forEach.call(containers, (container: HTMLDivElement) => {
      const headerRow = container.querySelector(
          '.project-table-header'
        ) as HTMLDivElement,
        firstRow = container.querySelector('.project-row') as HTMLDivElement;
      if (firstRow) {
        const diff = headerRow.clientWidth - firstRow.clientWidth;
        headerRow.style.paddingRight = diff + 'px';
      }
    });
  };

  private closeAttachments = () => {
    this.setState({
      attachmentProjectId: undefined,
      attachmentMilestoneId: undefined,
      attachmentProjectNo: undefined,
    });
  };

  private _filterGlobal = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    this.setState({ globalFilterText: val });
  };

  private _filter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    const field = e.currentTarget.getAttribute('name') || '';
    const dataType = 'string';

    this.props.filter(field, val, dataType);
  };

  private openAttachmentViewer = (
    e: React.MouseEvent<HTMLElement>,
    pId: number,
    mId?: number
  ) => {
    e.preventDefault();
    const pNo = (this.props.p.projects.find((p) => p.id == pId) as ProjectVm)
        .projectNumber,
      clientName = (this.props.p.projects.find((p) => p.id == pId) as ProjectVm)
        .clientName;
    this.setState({
      attachmentProjectId: pId,
      attachmentMilestoneId: mId,
      attachmentProjectNo: pNo,
      attachmentClientName: clientName,
    });
  };

  private toggleNewProjectForm = () =>
    this.setState({ showNewProjectForm: !this.state.showNewProjectForm });

  _toggleFiltersRow = () =>
    this.setState({ showFiltersRow: !this.state.showFiltersRow });

  _toggleEdit = () =>
    this.setState({ editingColumns: !this.state.editingColumns });

  _toggleMilestones = (e: React.MouseEvent<HTMLElement>, id: number) => {
    e.preventDefault();
    this.props.toggleMilestones(id);
  };

  _addFavorite = (e: React.MouseEvent<HTMLElement>, id: number) => {
    e.preventDefault();
    this.props.addFavorite(id);
  };

  _removeFavorite = (e: React.MouseEvent<HTMLElement>, id: number) => {
    e.preventDefault();
    this.props.removeFavorite(id);
  };

  _toggleShowComplete = () => {
    this.props.getProjects(!this.state.showComplete);
    this.setState({ showComplete: !this.state.showComplete });
  };

  _handleDateFilter = (dateProp: string, range?: DateRange) => {
    this.props.filter(dateProp, range || null, 'date');
  };

  _getFilteredProjects = () => {
    const { filterOptions } = this.props.p;
    let projects = JSON.parse(JSON.stringify(this.props.p.projects)) as ProjectVm[];

    if (filterOptions && filterOptions.length) {
      filterOptions.forEach((x) => {
        if (x.dataType === 'string') {
          // @ts-ignore
          projects = projects.filterByNestedProp(x.prop.toString(), x.value);
        } else if (x.dataType === 'date') {
          projects = projects.filter((p: ProjectVm) => {
            const dateStrVal = p[camelize(x.prop)];
            if (dateStrVal !== null && dateStrVal.length !== 0) {
              const dateVal = new Date(dateStrVal);
              //if (typeof (x.range.start)
              if (
                dateVal >= (x.value as DateRange).start.toDate() &&
                dateVal <= (x.value as DateRange).end.toDate()
              ) {
                return true;
              }
            }
            return false;
          });
        }
      });

      projects = projects.filter((x) => x !== undefined);
    }

    if (this.state.globalFilterText.length) {
      projects = projects.filterByMultiStringProp(
        [
          'projectNumber',
          'internalPM',
          'externalPM',
          'brandName',
          'address',
          'name',
          'clientName',
        ],
        this.state.globalFilterText
      );
    }

    if (this.state.sortInfo.sortProp.length) {
      projects = projects.sort((a, b) => {
        const aVal = a[this.state.sortInfo.sortProp],
          bVal = b[this.state.sortInfo.sortProp];
        if (!bVal) return -1;
        if (!aVal) return 1;
        if (aVal === bVal) return 0;
        if (this.state.sortInfo.sortAsc) return aVal > bVal ? 1 : -1;
        else return aVal > bVal ? -1 : 1;
      });
    }

    return projects;
  };

  _sort = (name: string) => {
    const { sortAsc } = this.state.sortInfo;
    this.setState({
      sortInfo: { sortProp: name, sortAsc: !sortAsc },
    });
  };

  private goToDetails = (id: any) => {
    this.props.history.push(`/project/${id}`);
  };

  public render() {
    const { userProjectColumns, allProjectProps, isLoading } = this.props.p,
      { user } = this.props.u,
      { editingColumns, showComplete } = this.state,
      projects = this._getFilteredProjects(),
      favorites = projects.filter((x) => x.userFavorite),
      nonFavorites = projects.filter((x) => !x.userFavorite);
    const projList = user ? (
        <ProjectList
          projects={projects}
          addFavorite={this._addFavorite}
          removeFavorite={this._removeFavorite}
          toggleAttachmentViewer={this.openAttachmentViewer}
          toggleMilestones={this._toggleMilestones}
          user={user}
          columns={userProjectColumns}
          editingColumns={editingColumns}
          allColumns={allProjectProps}
          addColumn={this.props.addColumn}
          removeColumn={this.props.removeColumn}
          tempReorder={this.props.tempReorder}
          reorderColumns={this.props.reorderColumns}
          onSort={this._sort}
        />
      ) : (
        []
      ),
      favoriteList = user ? (
        <ProjectList
          projects={favorites}
          addFavorite={this._addFavorite}
          removeFavorite={this._removeFavorite}
          toggleAttachmentViewer={this.openAttachmentViewer}
          toggleMilestones={this._toggleMilestones}
          user={user}
          columns={userProjectColumns}
          editingColumns={editingColumns}
          allColumns={allProjectProps}
          addColumn={this.props.addColumn}
          removeColumn={this.props.removeColumn}
          tempReorder={this.props.tempReorder}
          reorderColumns={this.props.reorderColumns}
          onSort={this._sort}
        />
      ) : (
        []
      );
    const budgetTotal = projects.reduce((a, b) => a + b.budget, 0);
    const costTotal = projects.reduce((a, b) => a + b.currentCost, 0);
    const hourglassClass = showComplete
      ? 'fas fa-hourglass-end'
      : 'fas fa-hourglass-half';

    return (
      <div id="project-list-page">
        <Loader loading={this.props.p.isSaving} />
        <MessageBox
          message={this.props.p.message}
          clearMessage={this.props.clearMessage}
        />
        {this.state.showNewProjectForm && (
          // @ts-ignore
          <this.pmComponent>
            {/* 
            // @ts-ignore */}
            <ProjectAdd close={this.toggleNewProjectForm} />
          </this.pmComponent>
        )}
        {this.state.attachmentProjectId && (
          <AttachmentViewer
            pId={this.state.attachmentProjectId}
            close={this.closeAttachments}
            // @ts-ignore
            projectNumber={this.state.attachmentProjectNo}
            clientName={this.state.attachmentClientName}
            title={this.state.attachmentProjectNo || ''}
          />
        )}
        {this.state.showCMKpiReportModal && (
          <CMKpiReportModal
            close={() => this.setState({ showCMKpiReportModal: false })}
          />
        )}
        <div className="pageHeader">
          {showComplete ? 'My Complete Projects' : 'My Projects'}
        </div>
        <Tabs
          name="project-tabs"
          onChange={(tab: string) => console.log(`Tab selected: ${tab}`)}
        >
          <div className="tab-links">
            <TabLink to="list" component="span">
              <span>List</span>
            </TabLink>
            <TabLink to="map" component="span">
              <span>Map</span>
            </TabLink>
          </div>
          <div className="content">
            <TabContent for="list">
              <div>
                <div className="col-sm-12 filter-section mobile-hide">
                  {/*                   <div className="my-col-7 projects-kpi-container mobile-hide">
                    <div
                      style={{
                        width: "33%",
                        maxWidth: "160px",
                        padding: "0px 6px",
                      }}
                    >
                      <div>
                        {isLoading ? (
                          <RectangleLoader height="94" />
                        ) : (
                          <KPI
                            size={"small"}
                            label="Project Count"
                            value={projects.length}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "33%",
                        maxWidth: "160px",
                        padding: "0px 6px",
                      }}
                    >
                      <this.noVendorComponent>
                        {isLoading ? (
                          <RectangleLoader height="94" />
                        ) : (
                          <KPI
                            size={"small"}
                            label="Budget"
                            value={toMoney(budgetTotal)}
                          />
                        )}
                      </this.noVendorComponent>
                    </div>
                    <div
                      style={{
                        width: "33%",
                        maxWidth: "160px",
                        padding: "0px 6px",
                      }}
                    >
                      <this.noVendorComponent>
                        {isLoading ? (
                          <RectangleLoader height="94" />
                        ) : (
                          <KPI
                            size={"small"}
                            label="Cost"
                            value={toMoney(costTotal)}
                          />
                        )}
                      </this.noVendorComponent>
                    </div>
                  </div> */}
                  <div
                    className="mobile-hide"
                    style={{
                      position: 'absolute',
                      top: '-40px',
                      right: '15px',
                    }}
                  >
                    <input
                      className="global-project-filter form-control"
                      placeholder="Search projects"
                      onChange={this._filterGlobal}
                      style={{
                        display: 'inline-block',
                        width: '245px',
                        marginTop: '3px',
                        bottom: '0px',
                        position: 'relative',
                      }}
                    />
                    <span
                      className="btn btn-sm btn-background-hover btn-round fas fa-chart-simple"
                      title="Run CM KPI Report"
                      onClick={() =>
                        this.setState({ showCMKpiReportModal: true })
                      }
                    ></span>
                    <span
                      className={cx(
                        'btn btn-sm btn-background-hover btn-round',
                        hourglassClass
                      )}
                      title="Toggle Showing Complete Projects"
                      onClick={this._toggleShowComplete}
                    ></span>
                    <span
                      className="btn btn-sm btn-background-hover btn-round fas fa-filter"
                      title="Toggle filters"
                      onClick={this._toggleFiltersRow}
                    ></span>
                    <span
                      className={cx(
                        'btn btn-sm btn-background-hover btn-round fas fa-edit',
                        editingColumns && 'btn-green'
                      )}
                      title="Edit column layout"
                      onClick={this._toggleEdit}
                    ></span>
                    <FileDownloader
                      actionPath={`api/Report/ProjectsExcel`}
                      fileType="XLSX"
                      queryParams={{ fileName: 'SiteVue Projects' }}
                      fileName="SiteVue Projects"
                      httpMethod="POST"
                      className="btn btn-sm btn-background-hover btn-round"
                      httpBody={projects.map((p: ProjectVm) => p.id)}
                    />
                    <this.pmComponent
                      class="header-btn-right mobile-hide"
                      componentType="span"
                      style={{ marginLeft: '10px' }}
                    >
                      <span
                        className="btn btn-sm btn-blue"
                        onClick={this.toggleNewProjectForm}
                      >
                        New Project
                      </span>
                    </this.pmComponent>
                  </div>
                </div>
                {this.state.showFiltersRow && this.renderFiltersRow()}
                {this.props.p.isLoading && <ProjectsLoader />}
                {/*{favorites.length ? (*/}
                {/*	<div*/}
                {/*		className={cx(*/}
                {/*			"project-list-container half-height col-sm-12"*/}
                {/*		)}*/}
                {/*	>*/}
                {/*		<div className="col-sm-12">*/}
                {/*			<h5 style={{ marginBottom: "0px" }}>My Favorites</h5>*/}
                {/*		</div>*/}
                {/*		<hr />*/}
                {/*		{favoriteList}*/}
                {/*	</div>*/}
                {/*) : (*/}
                {/*	[]*/}
                {/*)}*/}
                <div
                  className={cx(
                    'project-list-container col-sm-12'
                    //favorites.length && "half-height"
                  )}
                >
                  {projList}
                </div>
              </div>
            </TabContent>
            <TabContent for="map">
              <div className="my-col-20 no-pad" style={{ height: '70vh' }}>
                <MapWithTable
                  projects={projects}
                  navigate={this.goToDetails}
                  filter={this._filter}
                  globalFilter={this._filterGlobal}
                />
              </div>
            </TabContent>
          </div>
        </Tabs>
      </div>
    );
  }

  private renderFiltersRow() {
    const { userProjectColumns } = this.props.p;

    const gridClass = `p-col-${userProjectColumns.length}`;

    const filters = userProjectColumns.map((x) => {
      if (x.valueFormatType === PropertyFormatType.Money) {
        return (
          <div
            className={cx(gridClass, 'mobile-hide')}
            key={x.projectPropertyName}
          ></div>
        );
      }
      if (x.valueFormatType === PropertyFormatType.Date) {
        return (
          <div
            className={cx(gridClass, 'mobile-hide')}
            key={x.projectPropertyName}
          >
            <SVDateRangePicker
              onChange={(range) =>
                this._handleDateFilter(x.projectPropertyName, range)
              }
            />
          </div>
        );
      }
      if (x.valueFormatType === PropertyFormatType.String) {
        const placeholder = `${x.propertyDisplayName}...`;
        return (
          <div
            className={cx(gridClass, 'mobile-hide')}
            key={x.projectPropertyName}
          >
            <input
              className=""
              placeholder={placeholder}
              name={camelize(x.projectPropertyName)}
              onChange={this._filter}
            />
          </div>
        );
      }
    });

    return (
      <div className="col-sm-12" style={{ paddingLeft: '6px' }}>
        <div className="project-info filters-row">
          <div className="min-110 project-number">
            <input
              className=""
              placeholder="Project #..."
              name="projectNumber"
              onChange={this._filter}
            />
          </div>
          <div className="min-130 project-name">
            <input
              className=""
              placeholder="Project Name..."
              name="name"
              onChange={this._filter}
            />
          </div>
          {filters}
        </div>
      </div>
    );
  }
}

//@ts-ignore
export default connect(
  (state: ApplicationState) => {
    return { p: state.projects, u: state.user };
  },
  ProjectStore.actionCreators
  // @ts-ignore
)(ProjectsView) as typeof ProjectsView;
