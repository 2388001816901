import * as React from 'react';
import Chart from 'react-google-charts';
import { useSelector } from 'react-redux';
import {
  formatKPIValue,
  getDataPoints,
  getNestedPropValue,
} from '../charts/chartHelpers';
import { GraphMeasureType } from '../charts/GraphPart';
import { Loader } from '../components/Loader';
import { sum } from '../helpers/formulas';
import { KPIGraphFormat } from '../interfaces/enums';
import { PunchItemStatus, PunchItemVm } from '../interfaces/interfaces';
import { RectangleLoader } from '../loaders/RectangleLoader';
import { StandardGridLoader } from '../loaders/StandardGridLoader';
import { punchItemsLoadingSelector } from '../store/punchlist';

import './punchlist-charts.css';
import { EChart } from '@kbox-labs/react-echarts';

const KnownStatuses = ['Draft', 'Sent', 'In Review', 'Work Required', 'Closed'];

export const PunchlistCharts = (props: { items: PunchItemVm[] }) => {
  const { items } = props;
  const loading = useSelector(punchItemsLoadingSelector) === 'loading';
  //const loading = false;

  return (
    <div className="punchlist-charts">
      <div className="punchlist-chart-container horizontal-bar-chart">
        {loading ? (
          <RectangleLoader height={'250px'} />
        ) : (
          <PunchItemsByAssigneeBarChart items={items} />
        )}
      </div>
      <div className="punchlist-chart-container pie-chart">
        {loading ? (
          <RectangleLoader height={'250px'} />
        ) : (
          <PunchItemsByStatusPieChart items={items} />
        )}
      </div>

      <div className="punchlist-kpi-column">
        <div className="punchlist-chart-container kpi-chart">
          {loading ? (
            <RectangleLoader />
          ) : (
            <PunchItemsResponseTimeKPI items={items} />
          )}
        </div>
        <div className="punchlist-chart-container kpi-chart">
          {loading ? (
            <RectangleLoader />
          ) : (
            <PunchItemsOpenKPI items={items} dueDate={new Date()} />
          )}
        </div>
      </div>
    </div>
  );
};

const PunchItemsByAssigneeBarChart = (props: { items: PunchItemVm[] }) => {
  const { items } = props;

  if (items.length === 0) return <h5>No items to display in chart</h5>;

  const counts = items.reduce((p, c) => {
    const name = getNestedPropValue(c, 'ballInCourtName');
    const status = getNestedPropValue(c, 'status');
    if (!Object.prototype.hasOwnProperty.call(p, name)) {
      p[name] = [0, 0, 0, 0, 0];
    }
    p[name][status]++;
    return p;
  }, {});

  return (
    <div>
      <EChart
        renderer={'svg'}
        style={{
          height: '250px',
          fontFamily: 'Montserrat',
        }}
        legend={{
          orient: 'vertical',
          right: '3%',
          top: '20%',
          textStyle: {
            width: 70,
            overflow: 'break',
            fontSize: 13,
            fontFamily: 'Montserrat',
          },
        }}
        title={{
          text: 'Items by Ball In Court',
          left: 140,
          top: 15,
          textStyle: { fontSize: 13, fontFamily: 'Montserrat' },
        }}
        grid={{
          bottom: 25,
          left: 0,
          top: 50,
          containLabel: true,
          width: '80%',
        }}
        tooltip={{}}
        color={['#f4d053', '#2aaaaa', '#2864e6', '#d9534f', '#303030']}
        xAxis={{
          minInterval: 5,
          name: 'Item Count',
          nameLocation: 'middle',
          nameGap: 25,
          nameTextStyle: {
            fontStyle: 'italic',
            fontSize: 13,
            fontFamily: 'Montserrat',
          },
        }}
        yAxis={{
          type: 'category',
          axisLabel: {
            fontSize: 13,
            fontFamily: 'Montserrat',
          },
        }}
        dataset={{
          dimensions: ['name', ...KnownStatuses],
          source: [
            ...Object.keys(counts)
              .slice()
              .reverse()
              .map((x, idx) => [x, ...counts[x]]),
          ],
        }}
        series={KnownStatuses.map((x) => ({
          type: 'bar',
          stack: 'stack',
          barWidth: '40%',
        }))}
      />
    </div>
  );
};

const PunchItemsByStatusPieChart = (props: { items: PunchItemVm[] }) => {
  const { items } = props;

  if (items.length === 0) return <h5>No items to display in chart</h5>;

  return (
    <div>
      <EChart
        renderer={'svg'}
        style={{
          height: '250px',
        }}
        tooltip={{
          formatter(params) {
            return `${params.marker}${params.name}<span style="float: right; margin-left: 20px"><b>${params.value.value}</b> (${params.percent}%)</span>`;
          },
        }}
        legend={{
          orient: 'vertical',
          right: '0%',
          top: 10,
          icon: 'circle',
        }}
        title={{
          text: 'Items by Status',
          left: 120,
          top: 10,
          textStyle: { fontSize: 11, fontFamily: 'Montserrat' },
        }}
        color={['#f4d053', '#2aaaaa', '#2864e6', '#d9534f', '#303030']}
        dataset={{
          source: [
            ...KnownStatuses.map((x) => {
              const ct = items.filter((i) => i.statusName === x).length;
              return { name: x, value: ct };
            }),
          ],
        }}
        series={[
          {
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['45%', '50%'],
            label: {
              position: 'inside',
              formatter: '{@value} ',
            },
          },
        ]}
      />
    </div>
  );
};

const PunchItemsResponseTimeKPI = (props: { items: PunchItemVm[] }) => {
  const responseTimes = props.items
    .filter((x) => x.status === PunchItemStatus.Closed)
    .map((x) => {
      //if (x.status === PunchItemStatus.Closed) {
      const diffTime =
        new Date(x.dateClosed).getTime() - new Date(x.createdDate).getTime();
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      //}
      //else {
      //    const diffTime = new Date().getTime() - new Date(x.createdDate).getTime()
      //    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      //}
    });

  const value = responseTimes.length
    ? formatKPIValue(
        responseTimes.reduce(sum) / responseTimes.length,
        KPIGraphFormat.Number
      )
    : 'N/A';

  return (
    <div className="graph-kpi-value">
      <h5>Avg Time To Closed (days)</h5>
      <h2>{value}</h2>
    </div>
  );
};

const PunchItemsOpenKPI = (props: { items: PunchItemVm[]; dueDate: Date }) => {
  //const value = props.dueDate > new Date() ? 0 : props.items.filter(x => x.status !== PunchItemStatus.Closed).length;
  const value = props.items.filter(
    (x) => x.status !== PunchItemStatus.Closed
  ).length;

  return (
    <div className="graph-kpi-value">
      <h5>Total Open Items</h5>
      <h2>{formatKPIValue(value, KPIGraphFormat.Number)}</h2>
    </div>
  );
};

// Using memo here because this can be a pure component
//export default React.memo(PunchlistCharts);
