import React, { useState } from 'react';
import DocIcon from 'src/components/DocIcon';
import { Modal2 } from 'src/components/Modal';
import {
  FederationCertification,
  IDocumentInfo,
} from 'src/interfaces/interfaces';

interface VendorFedCertsGridRowProps {
  cert: FederationCertification;
  onRemoveCert: (certId: number) => void;
  onAddCert: (type: number, file: File) => void;
}
export const VendorFedCertsGridRow = ({
  cert,
  onRemoveCert,
  onAddCert,
}: VendorFedCertsGridRowProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      {showDeleteModal && (
        <Modal2 dialogStyle={{ marginTop: '38px', width: '400px' }}>
          <div className="modal-header">
            <h5 style={{ marginBottom: '0px' }}>Certification Removal</h5>
          </div>
          <div className="modal-body">
            <div style={{ fontSize: '13px' }}>
              Are you sure you want to delete this certification?
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-sm btn-danger"
              onClick={() => onRemoveCert(cert.id)}
            >
              Delete
            </button>
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Close
            </button>
          </div>
        </Modal2>
      )}
      <div className="sv-grid-line" key={cert.id}>
        <div style={{ display: 'flex', alignItems: 'center' }} className="col">
          {cert.type.name}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }} className="col">
          {new Date(cert.dtLastModified).toLocaleString()}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }} className="col">
          <input
            type="file"
            data-id={cert.id}
            onChange={(e) =>
              setSelectedFile(e.target.files?.length ? e.target.files[0] : null)
            }
          />
          <button
            style={{ marginLeft: '4px' }}
            disabled={selectedFile == null}
            className="btn btn-sm btn-blue"
            onClick={() => selectedFile && onAddCert(cert.typeId, selectedFile)}
          >
            Upload
          </button>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          className="col"
        >
          <DocIcon
            key={cert.id}
            doc={
              {
                id: cert.id,
                filePath: cert.attachment.filePath,
                timestamp: cert.dtLastModified,
              } as IDocumentInfo
            }
          />
          <span
            className="fas fa-trash-alt fa-2xl btn  btn-background-hover btn-round"
            onClick={() => setShowDeleteModal(true)}
          ></span>
        </div>
      </div>
    </>
  );
};
