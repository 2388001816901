import React from 'react';

export const AccountDeletion = () => {
  return (
    <div id="loginForm">
      <h2>Account Deletion Request</h2>
      <hr />
      <p style={{ fontSize: '14px' }}>
        If you have any questions about your account details or would like to
        have some or all of your account information deleted, please submit an
        email to{' '}
        <a href="mailto:helpdesk@core-states.com">helpdesk@core-states.com</a>{' '}
        and we will be happy to answer your questions and work with you to
        remove your account information.
      </p>
    </div>
  );
};
