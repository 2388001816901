import React from 'react';
import { FileExplorerItem } from '../../interfaces/interfaces';
import Modal from '../Modal';

interface FileExplorerMoveMultiItemModalProps {
  items: FileExplorerItem[];
  subPath: string | undefined;
  onMoveItems: () => void;
  onToggleMovingModal: () => void;
}

export const FileExplorerMoveMultiItemModal = ({
  items,
  subPath,
  onMoveItems,
  onToggleMovingModal,
}: FileExplorerMoveMultiItemModalProps) => {
  return (
    <Modal>
      <div className="modal-header">
        <h4>Move Files</h4>
      </div>
      <div className="modal-body">
        <div style={{ fontSize: '14px' }}>
          Are you sure you want to move these items to {subPath}?
        </div>
        <p>
          <b>Note:</b> This will remove these items from thier current location
        </p>
        <ul>
          {items.map((item) => (
            <li key={item.name}>{item.name}</li>
          ))}
        </ul>
      </div>
      <div className="modal-footer">
        <button className="btn btn-sm btn-blue" onClick={onMoveItems}>
          Move
        </button>
        <button
          className="btn btn-sm btn-secondary"
          onClick={onToggleMovingModal}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};
