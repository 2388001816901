import React, { useEffect } from 'react';
import { KeyVaultActions, partnerRegKeySelector } from 'src/store/keyvault/index';
import { TabContent, TabLink, Tabs } from 'src/tabs/index';
import { F40Detail } from './F40Detail';
import { PartnerRegistrationUrl } from './PartnerRegistrationConstants';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as GlobalActions } from '../store/global';
import { UnvettedPartner } from 'src/interfaces/interfaces';
import { FinancialsFormComponent } from './FinancialInfo';
import { Loader } from '../components/Loader';

interface VendorRegistrationReviewProps {
  partnerId?: number;
}

export const VendorRegistrationReview = ({
  partnerId,
}: VendorRegistrationReviewProps) => {
  const [partner, setPartner] = React.useState(null as UnvettedPartner | null);
  const [loading, setLoading] = React.useState(false);  
  const apiKey = useSelector(partnerRegKeySelector);
  const dispatch = useDispatch();

  const _getPartner = (id: number) => {
    setLoading(true);
    fetch(
      `${PartnerRegistrationUrl}/api/subform/FormExternal?vendorId=${id}`,

      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      }
    )
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        setLoading(false);
        if (resOk) {
          setPartner(data);
        } else {
          dispatch(GlobalActions.updateMessage(data.message));
        }
      });
  };

  useEffect(() => {
    dispatch(KeyVaultActions.getPartnerRegistrationKey());
  }, []);

  useEffect(() => {
    if (apiKey && partnerId) {
      _getPartner(partnerId);
    }
  }, [apiKey, partnerId]);

  return (
    <div>
      <Loader loading={loading} />
      <Tabs
        name="vendor-tabs"
        onChange={(t: string) => {}}
        renderActiveTabContentOnly={true}
      >
        <div className="tab-links">
          <TabLink to="csqf" component="span">
            <span>CSQF / F40</span>
          </TabLink>
          <TabLink to="info" component="span">
            <span>Financial Info</span>
          </TabLink>
          <TabLink to="notes" component="span">
            <span>Review Notes</span>
          </TabLink>
        </div>
        <div
          className="content custom-scrollbar"
          style={{ maxHeight: 'calc(100vh - 250px)', overflowY: 'auto' }}
        >
          <TabContent for="csqf">
            {partner ? (
              <F40Detail
                partner={partner}
                refresh={() => _getPartner(partner.id)}
              />
            ) : (
              <h4 style={{ textAlign: 'center' }}>No detail record found</h4>
            )}
          </TabContent>
          <TabContent for="info">
            {partner ? (
              <FinancialsFormComponent
                partner={partner}
                refresh={() => _getPartner(partner.id)}
              />
            ) : (
              <h4 style={{ textAlign: 'center' }}>No detail record found</h4>
            )}
          </TabContent>

          <TabContent for="notes">
            <div style={{width: '50%'}}>
              <h5>CSQF / F40 Review Notes</h5>
              <p style={{backgroundColor: 'rgb(240, 240, 240)', padding: '10px 5px', borderRadius: '3px'}}>
                {partner?.f40Form?.reviewNotes ?? 'No notes available'}
              </p>
            </div>
            <div style={{ width: '50%' }}>
              <h5>Financial Info Review Notes</h5>
              <p style={{ backgroundColor: 'rgb(240, 240, 240)', padding: '10px 5px', borderRadius: '3px' }}>
                {partner?.financialsForm?.reviewNotes ?? 'No notes available'}
              </p>
            </div>
          </TabContent>
        </div>
      </Tabs>
    </div>
  );
};
