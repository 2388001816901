import React, { useCallback, useEffect, useState } from 'react';
import { Loader } from '../Loader';
import { MessageBox } from '../MessageBox';
import HoverImagePreview from '../HoverImagePreview';
import PhotoCarousel from '../PhotoCarousel';
import { FolderSubscriber } from '../FolderSubscriber';
import { FileExplorerLoader } from '../../loaders/FileExplorerLoader';
import { FileShareModal } from '../FileShareModal';
import {
  FolderPermissionLevel,
  FolderPermissionsModal,
} from '../FolderPermissionsModal';
import styled from 'styled-components';
import { FileExplorerItem } from 'src/interfaces/interfaces';
import { ExplorerItemType, FileExplorerType } from 'src/interfaces/enums';
import {
  copyFileExplorerItem,
  createFolder,
  getFileExplorerItems,
  modifyFolder,
  moveFileExplorerItem,
  moveFileExplorerItems,
  removeItem,
  removeItems,
  subscribeToItem,
  unsubscribeToItem,
  uploadFileExplorerItem,
} from 'src/services/fileExplorer';
import { FileExplorerItemLines } from './FileExplorerItems';
import { FileExplorerDeleteModal } from './FileExplorerDeleteModal';
import { FileExplorerMoveItemModal } from './FileExplorerMoveItemModal';
import { Droppable } from '../DragDrop';
import { useDispatch, useSelector } from 'react-redux';
import { MoveEvent, WindowsActions } from 'src/store/window/window.reducer';
import {
  areWindowsLoadingSelector,
  moveEventSelector,
  shouldWindowsReloadSelector,
} from 'src/store/window/window.selectors';
import { DropResult } from '@hello-pangea/dnd';
import { FileExplorerMoveMultiItemModal } from './FileExplorerMoveMultiItemModal';
import { FileExplorerDeleteMulitItemModal } from './FileExplorerDeleteMultiItemModal';

interface FileExplorerProps {
  startingDir: string;
  homeDir?: string;
  breadCrumbStartLevel: number;
  canSubscribeUsers: boolean;
  params: any;
  type?: FileExplorerType;
  canOpenWindow?: boolean;
  onOpenWindow?: () => void;
  isInModal?: boolean;
  droppableId?: string;
  draggableId?: string;
}

const lockedFolders = [
  'Field Reports',
  'Milestones',
  'RFI-',
  'SUB-',
  'Checklists',
];
const extendedLockFolders = lockedFolders.concat([
  'RFIs',
  'Submittals',
  'Procurement',
  'Quotes',
]);

const AddWindowButton = styled.button`
  margin-right: 16px;
`;

const AddFolderButton = styled.button`
  margin-right: 12px;
  border-width: 1px;
  border-color: gray;
`;

const FileExplorerActionsDiv = styled.div({
  float: 'right',
  '& i': {
    marginRight: '15px',
    marginLeft: '8px',
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
});

const ExplorerContainerDiv = styled.div<{ isInModal: boolean | undefined }>(
  (props) => ({
    width: props.isInModal ? '100%' : 'calc(100% - 235px)',
    padding: props.isInModal ? undefined : '15px',
    display: props.isInModal ? 'flex' : undefined,
    flexDirection: props.isInModal ? 'column' : undefined,
    height: props.isInModal ? '90%' : undefined,
    marginRight: props.isInModal ? '0 !important' : '15px',
  })
);

const FileExplorerDiv = styled.div<{ isInModal: boolean | undefined }>(
  (props) => ({
    border: props.isInModal ? 'unset' : undefined,
    padding: props.isInModal ? 0 : undefined,
    height: props.isInModal ? '100%' : undefined,
  })
);

const DroppableDiv = styled.div<{ isDraggingOver: boolean }>((props) => ({
  backgroundColor: props.isDraggingOver ? 'skyblue' : undefined,
  minHeight: '50px',
}));

export const FileExplorer = ({
  startingDir,
  homeDir,
  breadCrumbStartLevel,
  params,
  canOpenWindow,
  onOpenWindow,
  isInModal,
  droppableId,
  draggableId,
}: FileExplorerProps) => {
  const [items, setItems] = useState<FileExplorerItem[]>([]);
  const [permissions, setPermissions] = useState<FolderPermissionLevel>(
    FolderPermissionLevel.None
  );
  const [subPath, setSubPath] = useState<string | undefined>(startingDir);
  const [searchView, setSearchView] = useState(false);
  const [previewPath, setPreviewPath] = useState<string>();
  const [subscribeFolderPath, setSubscribeFolderPath] = useState<string>();
  const [permissionFolderPath, setPermissionFolderPath] = useState<string>();
  const [addToFolderName, setAddToFolderName] = useState<string>();
  const [editFolderName, setEditFolderName] = useState<string>();
  const [deleteItemName, setDeleteItemName] = useState<string>();
  const [hoveredName, setHoveredName] = useState<string>();
  const [copiedForMovingItem, setCopiedForMovingItem] =
    useState<FileExplorerItem>();
  const [showMovingModal, setShowMovingModal] = useState(false);
  const [showMultiMovingModal, setShowMultiMovingModal] = useState(false);
  const [showMultiDeletingModal, setShowMultiDeletingModal] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState<number>();
  const [sharePath, setSharePath] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState<string>();
  const dispatch = useDispatch();
  const moveEvent = useSelector(moveEventSelector);
  const isModalLoading = useSelector(areWindowsLoadingSelector);
  const homeDirectory = homeDir !== undefined ? homeDir : startingDir;
  const shouldTriggerModalReload = useSelector(shouldWindowsReloadSelector);
  const [selectedItems, setSelectedItems] = useState<FileExplorerItem[]>([]);
  const [selectedMovingItems, setSelectedMovingItems] = useState<
    FileExplorerItem[]
  >([]);
  const [selectedDeletingItems, setSelectedDeletingItems] = useState<
    FileExplorerItem[]
  >([]);

  useEffect(() => {
    searchItems(startingDir);
  }, [startingDir, breadCrumbStartLevel]);

  useEffect(() => {
    if (shouldTriggerModalReload) {
      searchItems(subPath);
    }
  }, [shouldTriggerModalReload]);

  useEffect(() => {
    if (
      moveEvent &&
      moveEvent.fileExplorerItem &&
      moveEvent.dragDropResult.destination?.droppableId == droppableId &&
      moveEvent.sourceSubPath !== subPath &&
      moveEvent.fileExplorerItem.path !== subPath
    ) {
      const moveItem = async () => {
        dispatch(WindowsActions.setIsLoading(true));
        const response = await moveFileExplorerItem(
          subPath,
          moveEvent.fileExplorerItem!
        );

        if (typeof response == 'boolean' && response) {
          searchItems(subPath);
        } else if (response && 'message' in response) {
          setMessage(response.message);
        } else {
          setMessage('Unknown Error');
        }
        dispatch(WindowsActions.clearMoveEvent());
        dispatch(WindowsActions.setIsLoading(false));
      };

      if (moveEvent.fileExplorerItem) {
        moveItem();
      }
    }
  }, [moveEvent]);

  const searchItems = async (subPathText?: string, searchText?: string) => {
    setLoading(true);
    setSubPath(subPathText);
    setSelectedItems([]);

    const response = await getFileExplorerItems(subPathText, searchText);
    if (response && !('message' in response)) {
      const searchView = searchText !== undefined && searchText.length > 0;
      setItems(response.items);
      setPermissions(response.permissions);
      setLoading(false);
      setSearchView(searchView);
    } else if (response && 'message' in response) {
      setLoading(false);
      setMessage(response.message);
    } else {
      setLoading(false);
      setMessage('Error');
    }
  };

  const filterItems = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.currentTarget.value;

    searchItems(subPath, searchText);
  };

  const crumbNav = (path: string) => {
    searchItems(path);
    const searchInput = document.querySelector(
      '.entry-search'
    ) as HTMLInputElement;
    searchInput.value = '';
  };

  const fileHover = (path?: string, name?: string) => {
    setPreviewPath(path);
    setHoveredName(name);
  };

  const folderHover = (name?: string) => {
    setHoveredName(name);
    setMessage(undefined);
  };

  const toggleAdd = (e: React.MouseEvent<HTMLElement>, name?: string) => {
    e.stopPropagation();
    setAddToFolderName(name);
  };

  const toggleEdit = (e: React.MouseEvent<HTMLElement>, name?: string) => {
    e.stopPropagation();
    setEditFolderName(name);
  };

  const toggleDelete = (e: React.MouseEvent<HTMLElement>, name?: string) => {
    e.stopPropagation();
    setDeleteItemName(name);
  };

  const toggleFileDelete = (
    e: React.MouseEvent<HTMLElement>,
    name?: string
  ) => {
    e.stopPropagation();
    setDeleteItemName(name);
  };

  const copyForMoving = (
    e: React.MouseEvent<HTMLElement>,
    item?: FileExplorerItem
  ) => {
    e.stopPropagation();
    setCopiedForMovingItem(item);
  };

  const copyToCloseout = async (
    e: React.MouseEvent<HTMLElement>,
    item: FileExplorerItem
  ) => {
    e.stopPropagation();
    const response = await copyFileExplorerItem(startingDir, item);

    if (typeof response == 'boolean' && response) {
      setMessage('Item successfully copied');
    } else if (response && 'message' in response) {
      setLoading(false);
      setMessage(response.message);
    } else {
      setLoading(false);
      setMessage('Error');
    }
  };

  const toggleSubscriptionModal = (e?, path?: string) => {
    if (e) e.stopPropagation();
    setSubscribeFolderPath(path);
  };

  const togglePermissionsModal = (e?, path?: string) => {
    if (e) e.stopPropagation();
    setPermissionFolderPath(path);
  };

  const toggleMovingModal = () => setShowMovingModal(!showMovingModal);

  const toggleMultiMovingModal = () =>
    setShowMultiMovingModal(!showMultiMovingModal);

  const toggleMultiDeleteModal = () =>
    setShowMultiDeletingModal(!showMultiDeletingModal);

  const openFileTab = (path) => window.open(path, '_blank');

  const clearMessage = () => setMessage(undefined);

  const upload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSaving(true);
    const input = e.currentTarget;
    const files = input.files;
    const form = new FormData();
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; ++i)
        form.append('files', files[i], files[i].name);
    }

    const response = await uploadFileExplorerItem(subPath, form);

    if (typeof response == 'boolean' && response) {
      setSaving(false);
      input.value = '';
      searchItems(subPath);
    } else if (response && 'message' in response) {
      setSaving(false);
      setMessage(response.message);
    } else {
      setSaving(false);
      setMessage('Unknown Error');
    }
  };

  const addFolder = async () => {
    const newSubPath = subPath
      ? addToFolderName
        ? subPath + '/' + addToFolderName
        : subPath
      : addToFolderName;

    const newFolder = (
        document.getElementById('add-folder-input') as HTMLInputElement
      ).value,
      inherit = (
        document.getElementById('inherit-permissions') as HTMLInputElement
      ).checked;

    const response = await createFolder(newSubPath, newFolder, inherit);

    if (typeof response == 'boolean' && response) {
      setAddToFolderName(undefined);
      searchItems(subPath);
    } else if (response && 'message' in response) {
      setSaving(false);
      setMessage(response.message);
    } else {
      setSaving(false);
      setMessage('Unknown Error');
    }
  };

  const editFolder = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const newName = (
      document.getElementById('edit-folder-input') as HTMLInputElement
    ).value;

    const response = await modifyFolder(subPath, editFolderName, newName);

    if (typeof response == 'boolean' && response) {
      searchItems(subPath);
    } else if (response && 'message' in response) {
      setSaving(false);
      setMessage(response.message);
    } else {
      setSaving(false);
      setMessage('Unknown Error');
    }
  };

  const deleteItem = async () => {
    const delSubPath = subPath
      ? subPath + '/' + deleteItemName
      : deleteItemName;

    const response = await removeItem(delSubPath);

    setDeleteItemName(undefined);

    if (typeof response == 'boolean' && response) {
      searchItems(subPath);
    } else if (response && 'message' in response) {
      setSaving(false);
      setMessage(response.message);
    } else {
      setSaving(false);
      setMessage('Unknown Error');
    }
  };

  const deleteItems = async () => {
    setSaving(true);
    const response = await removeItems(selectedDeletingItems);

    setSelectedItems([]);
    setSelectedMovingItems([]);
    setSelectedDeletingItems([]);

    if (typeof response == 'boolean' && response) {
      setSaving(false);
      searchItems(subPath);
    } else if (response && 'message' in response) {
      setSaving(false);
      setMessage(response.message);
    } else {
      setSaving(false);
      setMessage('Unknown Error');
    }
  };

  const moveItem = async () => {
    if (copiedForMovingItem) {
      const response = await moveFileExplorerItem(subPath, copiedForMovingItem);

      if (typeof response == 'boolean' && response) {
        setCopiedForMovingItem(undefined);
        setShowMovingModal(false);
        searchItems(subPath);
      } else if (response && 'message' in response) {
        setSaving(false);
        setMessage(response.message);
      } else {
        setSaving(false);
        setMessage('Unknown Error');
      }
    }
  };

  const moveItems = async () => {
    if (selectedMovingItems.length > 0) {
      setSaving(true);

      const response = await moveFileExplorerItems(
        subPath,
        selectedMovingItems
      );
      if (typeof response == 'boolean' && response) {
        setSaving(false);
        setSelectedItems([]);
        setSelectedMovingItems([]);
        setSelectedDeletingItems([]);
        setShowMovingModal(false);
        searchItems(subPath);
      } else if (response && 'message' in response) {
        setSaving(false);
        setMessage(response.message);
      } else {
        setSaving(false);
        setMessage('Unknown Error');
      }
    }
  };

  const subscribe = async (e, path: string) => {
    e.stopPropagation();
    const response = await subscribeToItem(path);

    if (typeof response == 'boolean' && response) {
      setMessage('Subscribed!');
      searchItems(subPath);
    } else if (response && 'message' in response) {
      setSaving(false);
      setMessage(response.message);
    } else {
      setSaving(false);
      setMessage('Unknown Error');
    }
  };

  const unsubscribe = async (e, path: string) => {
    e.stopPropagation();

    const response = await unsubscribeToItem(path);

    if (typeof response == 'boolean' && response) {
      setMessage('Unsubscribed!!');
      searchItems(subPath);
    } else if (response && 'message' in response) {
      setSaving(false);
      setMessage(response.message);
    } else {
      setSaving(false);
      setMessage('Unknown Error');
    }
  };

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source } = result;

      if (!destination) {
        return;
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }
      if (source.droppableId == droppableId) {
        const item = items[result.source.index];

        if (item) {
          const moveEvent: MoveEvent = {
            dragDropResult: result,
            fileExplorerItem: item,
            sourceSubPath: subPath,
          };
          dispatch(WindowsActions.setMoveEvent(moveEvent));
        }
      }
    },
    [items]
  );

  const onItemSelect = useCallback(
    (item: FileExplorerItem, selected: boolean) => {
      if (!selected) {
        setSelectedItems(selectedItems.filter((x) => x.name !== item.name));
      } else {
        setSelectedItems([...selectedItems, item]);
      }
    },
    [selectedItems]
  );

  const toggleCarousel = (idx?: number) => setCarouselIndex(idx);

  const toggleShareModal = (path?: string) => setSharePath(path);

  const pathSplit = subPath ? subPath.split('/') : [''];
  const crumbCount = pathSplit.length - (breadCrumbStartLevel + 1);
  const locked =
    extendedLockFolders.findIndex((x) => pathSplit.indexOf(x) !== -1) !== -1;
  const adding = addToFolderName === '';
  const crumbs =
    crumbCount > 0
      ? pathSplit
          .slice(breadCrumbStartLevel + 1, pathSplit.length)
          .map((x, i) => {
            const crumbPath = pathSplit
              .slice(0, breadCrumbStartLevel + i + 2)
              .join('/');
            return (
              <span
                key={i}
                className="crumb"
                onClick={() => crumbNav(crumbPath)}
              >
                {' '}
                {x}
                {i !== crumbCount - 1 ? (
                  <span className="fas fa-chevron-right"></span>
                ) : (
                  []
                )}
              </span>
            );
          })
      : [];

  const canAddOrUpload = !locked && permissions === FolderPermissionLevel.Write;

  const itemLinesProps = {
    items,
    locked,
    searchView,
    hoveredName,
    addToFolderName,
    editFolderName,
    subPath,
    startingDir,
    onFileHover: fileHover,
    onFolderHover: folderHover,
    onToggleCarousel: toggleCarousel,
    onOpenFileTab: openFileTab,
    onSearchItems: searchItems,
    onEditFolder: editFolder,
    onAddFolder: addFolder,
    onToggleEdit: toggleEdit,
    onSubscribe: subscribe,
    onUnsubscribe: unsubscribe,
    onCopyToCloseout: copyToCloseout,
    onTogglePermissionsModal: togglePermissionsModal,
    onToggleShareModal: toggleShareModal,
    onToggleAdd: toggleAdd,
    onToggleDelete: toggleDelete,
    onToggleFileDelete: toggleFileDelete,
    onCopyForMoving: copyForMoving,
    onItemSelect: onItemSelect,
    selectedItems: selectedItems,
    extendedLockFolders,
  };

  return (
    <FileExplorerDiv isInModal={isInModal} className="file-explorer">
      <MessageBox message={message} clearMessage={clearMessage} />
      <Loader loading={saving} />
      {carouselIndex !== undefined && (
        <PhotoCarousel
          startingIndex={carouselIndex}
          close={() => toggleCarousel()}
          photos={items
            .filter(
              (x) =>
                x.type === ExplorerItemType.File &&
                x.name.indexOf('.pdf') === -1
            )
            .map((x) => x.path)}
        />
      )}
      <ExplorerContainerDiv
        isInModal={isInModal}
        className="explorer-container"
      >
        <div className="file-breadcrumb-trail">
          <span>
            <span className="fas fa-folder-open"></span>
            <span className="crumb" onClick={() => crumbNav(homeDirectory)}>
              Home
              {subPath && subPath.length ? (
                <span className="fas fa-chevron-right"></span>
              ) : (
                []
              )}
            </span>
            {crumbs}
          </span>

          <FileExplorerActionsDiv>
            {selectedItems.length > 0 && (
              <span>
                <span
                  onClick={() => setSelectedMovingItems(selectedItems)}
                  title="Copy to Move Items"
                  className="btn btn-background-hover btn-round fa-solid fa-copy fa-xl"
                ></span>
                <span
                  onClick={() => {
                    setSelectedDeletingItems(selectedItems);
                    toggleMultiDeleteModal();
                  }}
                  title="Delete Items"
                  className="btn btn-background-hover btn-round fa-solid fa-trash fa-xl"
                ></span>
              </span>
            )}
            {canOpenWindow && !isInModal && (
              <AddWindowButton onClick={onOpenWindow}>
                Open Window
              </AddWindowButton>
            )}
            {canOpenWindow && isInModal && (
              <i
                onClick={onOpenWindow}
                className="fa-solid fa-up-right-from-square fa-xl"
              ></i>
            )}
            <input
              className="entry-search mobile-hide"
              placeholder="Search"
              onChange={filterItems}
            />
          </FileExplorerActionsDiv>
        </div>
        <div className="file-explorer-list custom-scrollbar">
          <div style={{ marginBottom: '5px' }}>
            {(loading || isModalLoading) && <FileExplorerLoader />}
            <div>
              {!loading && !isModalLoading && !droppableId && !draggableId && (
                <FileExplorerItemLines
                  {...itemLinesProps}
                ></FileExplorerItemLines>
              )}
              {!loading && !isModalLoading && droppableId && draggableId && (
                <Droppable
                  onDragEnd={(result) => onDragEnd(result)}
                  type="File"
                  droppableId={droppableId}
                >
                  {(provided, snapshot) => (
                    <DroppableDiv
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      isDraggingOver={snapshot.isDraggingOver}
                    >
                      <FileExplorerItemLines
                        {...itemLinesProps}
                        draggableId={draggableId}
                      ></FileExplorerItemLines>
                      {provided.placeholder}
                    </DroppableDiv>
                  )}
                </Droppable>
              )}
              {adding && (
                <div className="new-folder-line" style={{ marginLeft: '4px' }}>
                  <input id="add-folder-input" /> &nbsp;
                  <div title="Inherit the permissions from the parent folder">
                    <span>Inherit permissions?</span>{' '}
                    <input type="checkbox" id="inherit-permissions" />
                  </div>
                  <button
                    title="Save"
                    className="btn btn-sm btn-blue fas fa-check"
                    onClick={addFolder}
                  ></button>
                  <button
                    title="Cancel"
                    className="btn btn-sm btn-outline-secondary fas fa-ban"
                    onClick={(e) => toggleAdd(e, undefined)}
                  ></button>
                </div>
              )}
            </div>
          </div>
          {!locked && copiedForMovingItem && (
            <button
              className="btn btn-sm btn-blue fas fa-paste"
              title="Move copied item here"
              onClick={toggleMovingModal}
            ></button>
          )}
          {!locked && selectedMovingItems.length > 0 && (
            <button
              className="btn btn-sm btn-blue fas fa-paste"
              title="Move copied items here"
              onClick={toggleMultiMovingModal}
            ></button>
          )}
          {canAddOrUpload && (
            <AddFolderButton onClick={(e) => toggleAdd(e, '')}>
              Add Folder
            </AddFolderButton>
          )}
          {!locked && canAddOrUpload && (
            <input
              type="file"
              multiple
              onChange={upload}
              style={{ display: 'inline-block' }}
            />
          )}
        </div>
      </ExplorerContainerDiv>
      {!isInModal && (
        <div className="hover-preview-container mobile-hide">
          <div className="hover-title">Preview</div>
          <HoverImagePreview
            previewPath={previewPath}
            style={{ position: 'absolute' }}
          />
        </div>
      )}
      {deleteItemName && (
        <FileExplorerDeleteModal
          folderName={deleteItemName}
          onToggleDelete={toggleDelete}
          onDeleteItem={deleteItem}
        />
      )}
      {showMultiDeletingModal && selectedDeletingItems.length > 0 && (
        <FileExplorerDeleteMulitItemModal
          items={selectedDeletingItems}
          onToggleDeleteModal={toggleMultiDeleteModal}
          onDeleteItems={deleteItems}
        />
      )}
      {/*deleteFileName && this.renderDeleteFileModal(deleteFileName)*/}
      {showMovingModal && (
        <FileExplorerMoveItemModal
          copiedForMovingItem={copiedForMovingItem}
          subPath={subPath}
          onMoveItem={moveItem}
          onToggleMovingModal={toggleMovingModal}
        />
      )}
      {showMultiMovingModal && selectedMovingItems.length > 0 && (
        <FileExplorerMoveMultiItemModal
          items={selectedMovingItems}
          subPath={subPath}
          onMoveItems={moveItems}
          onToggleMovingModal={toggleMultiMovingModal}
        />
      )}
      {sharePath && (
        <FileShareModal path={sharePath} close={toggleShareModal} />
      )}
      {subscribeFolderPath && (params.pId || params.id) && (
        <FolderSubscriber
          folderPath={subscribeFolderPath}
          projectId={params.id}
          close={() => toggleSubscriptionModal()}
        />
      )}
      {permissionFolderPath && (
        <FolderPermissionsModal
          relPath={permissionFolderPath}
          close={() => togglePermissionsModal()}
        />
      )}
    </FileExplorerDiv>
  );
};
