import { EChart } from '@kbox-labs/react-echarts';
import React from 'react';
import { IssueVm } from 'src/interfaces/interfaces';

interface IssuePieChartProps {
  issues: IssueVm[];
}

const KnownStatuses = ['Draft', 'Sent', 'In Progress', 'In Review', 'Complete'];

export const IssuePieChart = ({ issues }: IssuePieChartProps) => {
  return (
    <div>
      <EChart
        renderer={'svg'}
        style={{
          height: '250px',
        }}
        tooltip={{
          formatter(params) {
            return `${params.marker}${params.name}<span style="float: right; margin-left: 20px"><b>${params.value.value}</b> (${params.percent}%)</span>`;
          },
        }}
        legend={{
          orient: 'vertical',
          right: '0%',
          top: 10,
          icon: 'circle',
        }}
        title={{
          text: 'Items by Status',
          left: 120,
          top: 10,
          textStyle: { fontSize: 11, fontFamily: 'Montserrat' },
        }}
        color={['#f4d053', '#2aaaaa', '#2864e6', '#d9534f', '#303030']}
        dataset={{
          source: [
            ...KnownStatuses.map((x) => {
              const ct = issues.filter((i) => i.statusName === x).length;
              return { name: x, value: ct };
            }),
          ],
        }}
        series={[
          {
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['45%', '50%'],
            label: {
              position: 'inside',
              formatter: '{@value} ',
            },
          },
        ]}
      />
    </div>
  );
};
