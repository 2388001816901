import React, { useCallback, useMemo, useState } from 'react';
import {
  CustomizableGrid,
  MetaFieldInfo,
} from 'src/components/CustomizableGrid';
import DocIcon from 'src/components/DocIcon';
import { CSharpTypeCode } from 'src/interfaces/enums';
import {
  FederationCertification,
  IDocumentInfo,
} from 'src/interfaces/interfaces';
import { fedCertTypesSelector } from 'src/store/vendors';
import { VendorFedCertAddModal } from './VendorFedCertAddModal';
import { useSelector } from 'react-redux';
import { VendorFedCertsGridRow } from './VendorFedCertsGridRow';

interface VendorFedCertsGridProps {
  fedCerts: FederationCertification[];
  onAddCert: (type: number, file: File) => void;
  onDeleteCert: (id: number) => void;
  loading: boolean;
}

const columns: MetaFieldInfo[] = [
  {
    displayName: 'Certification',
    propName: 'type.name',
    jsType: 'string',
    propType: CSharpTypeCode.String,
  },
  {
    displayName: 'Last Modified Date',
    propName: 'dtLastModified',
    jsType: 'date',
    propType: CSharpTypeCode.DateTime,
  },
  {
    displayName: 'Upload',
    propName: 'type.name',
    jsType: 'string',
    propType: CSharpTypeCode.String,
  },
  {
    displayName: 'Attachment',
    propName: 'attachment.filePath',
    jsType: 'string',
    propType: CSharpTypeCode.String,
  },
];

export const VendorFedCertsGrid = ({
  fedCerts,
  onAddCert,
  onDeleteCert,
  loading,
}: VendorFedCertsGridProps) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const certTypes = useSelector(fedCertTypesSelector);

  const renderItem = useCallback(
    (cert: FederationCertification) => {
      return (
        <VendorFedCertsGridRow
          cert={cert}
          onAddCert={onAddCert}
          onRemoveCert={onDeleteCert}
        />
      );
    },
    [fedCerts]
  );

  const currentCertTypes = useMemo(() => {
    return fedCerts.map((fc) => fc.type);
  }, [fedCerts]);

  const availableCertTypes = useMemo(() => {
    return certTypes.filter(
      (ct) => !currentCertTypes.some((cct) => cct.id === ct.id)
    );
  }, [certTypes, currentCertTypes]);

  return (
    <>
      {isModalVisible && (
        <VendorFedCertAddModal
          onModalClose={() => setIsModalVisible(false)}
          availableCertTypes={availableCertTypes}
          onAddCert={onAddCert}
        />
      )}
      <CustomizableGrid
        items={fedCerts ?? []}
        columns={columns}
        hideLoadingWhenEmpty={true}
        mapCallback={renderItem}
        evenColumnSpacing={true}
        addCallback={() => setIsModalVisible(true)}
        loading={loading}
      />
    </>
  );
};
