import {
  FederationCertificationType,
  ProcessingNotification,
  ProcessingStatus,
  VendorVm,
} from 'src/interfaces/interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface VendorsState {
  vendorsAndSubs: VendorVm[];
  fedCertTypes: FederationCertificationType[];
}

export const initialState: VendorsState = {
  vendorsAndSubs: [] as VendorVm[],
  fedCertTypes: [],
};

const { actions: VendorsActions, reducer: VendorsReducer } = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    getVendorsAndSubs() {},
    setVendorsAndSubcs(state, action: PayloadAction<{ records: VendorVm[] }>) {
      state.vendorsAndSubs = action.payload.records;
    },
    getVendorFedCertTypes() {},
    setVendorFedCertTypes(
      state,
      action: PayloadAction<FederationCertificationType[]>
    ) {
      state.fedCertTypes = action.payload;
    },
  },
});

export { VendorsActions, VendorsReducer };

export default VendorsReducer;
