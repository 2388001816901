import { EChart } from '@kbox-labs/react-echarts';
import React from 'react';
import { IssueVm } from 'src/interfaces/interfaces';

interface IssueBarChartProps {
  issues: IssueVm[];
}

const KnownStatuses = ['Draft', 'Sent', 'In Progress', 'In Review', 'Complete'];

export const IssueBarChart = ({ issues }: IssueBarChartProps) => {
  const userStatusCounts = issues.reduce((prev, curr) => {
    const assigneeKey = curr['assigneeName'];
    const statusKey = curr['status'];

    prev[assigneeKey] = prev[assigneeKey] || [0, 0, 0, 0, 0];

    prev[assigneeKey][statusKey]++;

    return prev;
  }, {});

  return (
    <>
      <EChart
        renderer={'svg'}
        style={{
          height: '250px',
          fontFamily: 'Montserrat',
        }}
        legend={{
          orient: 'vertical',
          right: '3%',
          top: '20%',
          textStyle: {
            width: 70,
            overflow: 'break',
            fontSize: 13,
            fontFamily: 'Montserrat',
          },
        }}
        title={{
          text: 'Items by Ball In Court',
          left: 140,
          top: 15,
          textStyle: { fontSize: 13, fontFamily: 'Montserrat' },
        }}
        grid={{
          bottom: 25,
          left: 0,
          top: 50,
          containLabel: true,
          width: '80%',
        }}
        tooltip={{}}
        color={['#f4d053', '#2aaaaa', '#2864e6', '#d9534f', '#303030']}
        xAxis={{
          minInterval: 5,
          name: 'Item Count',
          nameLocation: 'middle',
          nameGap: 25,
          nameTextStyle: {
            fontStyle: 'italic',
            fontSize: 13,
            fontFamily: 'Montserrat',
          },
        }}
        yAxis={{
          type: 'category',
          axisLabel: {
            fontSize: 13,
            fontFamily: 'Montserrat',
          },
        }}
        dataset={{
          dimensions: ['name', ...KnownStatuses],
          source: [
            ...Object.keys(userStatusCounts)
              .slice()
              .reverse()
              .map((x, idx) => [x, ...userStatusCounts[x]]),
          ],
        }}
        series={KnownStatuses.map((x) => ({
          type: 'bar',
          stack: 'stack',
          barWidth: '40%',
        }))}
      />
    </>
  );
};
