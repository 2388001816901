import * as React from 'react';
import { Chart } from 'react-google-charts';
import cx from 'classnames';
import { EChart } from '@kbox-labs/react-echarts';

interface Props {
  options?: any;
  graphId: string;
  dataPoints: any[];
  columns?: string[];
  hideTooltip?: boolean;
  colors?: any;
}

export default class PieChart extends React.Component<Props, any> {
  render() {
    const { dataPoints, options, columns, hideTooltip } = this.props;
    const firstData = columns || ['Task', 'Items', { role: 'style' }],
      data = [firstData, ...dataPoints];

    return (
      <div className={cx('chart-container', hideTooltip && 'no-tooltip')}>
        <EChart
          renderer={'svg'}
          style={{
            height: '350px',
          }}
          tooltip={{
            formatter(params) {
              return `${params.marker}${params.name}<br/><span style="float: left;"><b>${params.data[1]}</b> (${params.percent}%)</span>`;
            },
          }}
          legend={{
            orient: 'vertical',
            right: '5%',
            top: 10,
            icon: 'circle',
            type: 'scroll',
            textStyle: {
              width: 200,
              overflow: 'break',
              fontSize: 13,
              fontFamily: 'Montserrat',
            },
          }}
          title={{
            text: options.title,
            left: 120,
            top: 10,
            textStyle: { fontSize: 11, fontFamily: 'Montserrat' },
          }}
          dataset={{
            source: [...dataPoints],
          }}
          color={options.colors}
          series={[
            {
              type: 'pie',
              center: ['45%', '50%'],
              radius: ['40%', '70%'],
              label: {
                position: 'inside',
                show: false,
              },
            },
          ]}
        />
      </div>
    );
  }
}
