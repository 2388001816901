import * as React from 'react';
import { Modal2 } from '../components/Modal';
import { VendorComplianceReport } from './VendorComplianceReport';

export const VendorComplianceReportModal = () => {
  const [open, setOpen] = React.useState(false);

  //React.useEffect(() => {
  //  alert(open);
  //}, [open])

  return <div>
    <button className='btn btn-x-sm btn-outline-secondary fas fa-file-certificate' onClick={() => setOpen(true)}></button>
    {open && <Modal2 dialogStyle={{maxWidth: '66%'}}>
      <div className='modal-header'>
        <h4>Vendor Compliance Report</h4>
      </div>
      <div className='modal-body'>
        <VendorComplianceReport />
      </div>
      <div className='modal-footer'>
        <button className='btn btn-x-sm btn-outline-secondary' onClick={() => setOpen(false)}>Close</button>
      </div>
    </Modal2>}
  </div>
}