import React, { useRef } from 'react';
import { Menu, Item } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { FileExplorerItem } from 'src/interfaces/interfaces';
import { FileDownloader } from '../FileDownloader';
import { ExplorerItemType } from '../DeprecatedFileExplorer';
import styled from 'styled-components';
import { FolderPermissionLevel } from '../FolderPermissionsModal';
import { MyAuthElement } from 'src/auth/Authorization';
import { KnownRoles } from 'src/auth/auth';

interface FileExplorerMenuProps {
  menuId: string;
  isItemLocked: boolean;
  item: FileExplorerItem | undefined;
  isSearchView: boolean;
  startingDir: string;
  onSubscribe: (e, path: string) => void;
  onUnsubscribe: (e, path: string) => void;
  onCopyToCloseout: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    item: FileExplorerItem
  ) => void;
  onTogglePermissionsModal: (e?, path?: string) => void;
  onToggleShareModal: (path?: string) => void;
  onToggleAdd: (e: React.MouseEvent<HTMLElement>, name?: string) => void;
  onToggleEdit: (e: React.MouseEvent<HTMLElement>, name?: string) => void;
  onCopyForMoving: (
    e: React.MouseEvent<HTMLElement>,
    item?: FileExplorerItem
  ) => void;
  onToggleDelete: (e: React.MouseEvent<HTMLElement>, name?: string) => void;
  onToggleFileDelete: (e: React.MouseEvent<HTMLElement>, name?: string) => void;
  onMenuHide: () => void;
}

const StyledI = styled.i({
  cursor: 'pointer !important',
  fontSize: 'unset',
  left: 'unset',
});

const StyledItem = styled(Item)({
  '& span': {
    cursor: 'pointer !important',
    fontSize: 'unset',
    left: 'unset',
  },
});

const SuperUserPmComp = MyAuthElement([
  KnownRoles.Admin,
  KnownRoles.CoreSuperUser,
  KnownRoles.CSGPM,
]);

export const FileExplorerMenu = ({
  menuId,
  isItemLocked,
  item,
  isSearchView,
  startingDir,
  onMenuHide,
  ...props
}: FileExplorerMenuProps) => {
  const folderDownloadRef = useRef<HTMLSpanElement>(null);
  const fileDownloadRef = useRef<HTMLSpanElement>(null);

  return (
    <Menu
      id={menuId}
      onVisibilityChange={(isVisblie) => {
        if (isVisblie == false) {
          onMenuHide();
        }
      }}
    >
      {item && (
        <>
          {isSearchView ? (
            <Item onClick={() => fileDownloadRef.current?.click()}>
              <FileDownloader
                ref={fileDownloadRef}
                actionPath="api/FileExplorer/Download"
                queryParams={{ filePath: encodeURI(item.path) }}
                fileType="file"
                className="undefined"
              />
              &nbsp; Download
            </Item>
          ) : (
            <>
              {!isItemLocked &&
                item.type === ExplorerItemType.Folder &&
                item.name !== 'RFIs' &&
                item.name !== 'Submittals' &&
                (item.isUserSubscribed ? (
                  <Item
                    onClick={({ event }) =>
                      props.onUnsubscribe(event, item.path)
                    }
                  >
                    <StyledI
                      title="Unsubscribe from this folder"
                      className="fas fa-eye-slash"
                    ></StyledI>
                    &nbsp; Unsubscribe
                  </Item>
                ) : (
                  <Item
                    onClick={({ event }) => props.onSubscribe(event, item.path)}
                  >
                    <StyledI
                      title="Subscribe to this folder"
                      className="fas fa-eye"
                    ></StyledI>
                    &nbsp; Subscribe
                  </Item>
                ))}
              {startingDir.indexOf('/Projects/') !== -1 &&
                item.path.indexOf('/Closeout') === -1 && (
                  <Item
                    onClick={({ event }) =>
                      props.onCopyToCloseout(
                        event as React.MouseEvent<HTMLElement, MouseEvent>,
                        item
                      )
                    }
                  >
                    <StyledI
                      title="Copy to closeout folder"
                      className="fas fa-book"
                    ></StyledI>
                    &nbsp; Copy to Closeout Folder
                  </Item>
                )}
              {item.type === ExplorerItemType.Folder && (
                <Item
                  onClick={({ event }) =>
                    props.onTogglePermissionsModal(event, item.path)
                  }
                >
                  <StyledI
                    title="View folder permissions"
                    className="fas fa-user-friends"
                  ></StyledI>
                  &nbsp; Folder Permissions
                </Item>
              )}
              {item.permissions === FolderPermissionLevel.Write && (
                <SuperUserPmComp>
                  <Item onClick={() => props.onToggleShareModal(item.path)}>
                    <StyledI className="fas fa-share-alt"></StyledI>
                    &nbsp; Share
                  </Item>
                </SuperUserPmComp>
              )}
              {!isItemLocked && item.type === ExplorerItemType.Folder && (
                <Item
                  onClick={({ event }) =>
                    props.onToggleAdd(
                      event as React.MouseEvent<HTMLElement, MouseEvent>,
                      item.name
                    )
                  }
                >
                  <StyledI
                    title="Add subfolder"
                    className="fas fa-plus"
                  ></StyledI>
                  &nbsp; Add Subfolder
                </Item>
              )}
              {!isItemLocked && item.type === ExplorerItemType.Folder && (
                <Item
                  closeOnClick={false}
                  onClick={({ event }) =>
                    props.onToggleEdit(
                      event as React.MouseEvent<HTMLElement, MouseEvent>,
                      item.name
                    )
                  }
                >
                  <StyledI title="Edit" className="fas fa-edit"></StyledI>
                  &nbsp; Edit
                </Item>
              )}
              {!isItemLocked && (
                <Item
                  onClick={({ event }) =>
                    props.onCopyForMoving(
                      event as React.MouseEvent<HTMLElement, MouseEvent>,
                      item
                    )
                  }
                >
                  <StyledI
                    title="Copy to Move"
                    className="fas fa-copy"
                  ></StyledI>
                  &nbsp; Copy to Move
                </Item>
              )}
              {item.type === ExplorerItemType.File && (
                <StyledItem onClick={() => fileDownloadRef.current?.click()}>
                  <FileDownloader
                    ref={fileDownloadRef}
                    actionPath="api/FileExplorer/Download"
                    queryParams={{ filePath: encodeURI(item.path) }}
                    fileType=""
                    className="undefined"
                  />
                  &nbsp; Download
                </StyledItem>
              )}

              {item.type === ExplorerItemType.Folder && (
                <StyledItem onClick={() => folderDownloadRef.current?.click()}>
                  <FileDownloader
                    ref={folderDownloadRef}
                    actionPath="api/FileExplorer/Zip"
                    queryParams={{ subPath: encodeURI(item.path) }}
                    fileType="zip"
                    className="undefined"
                  />
                  &nbsp; Download
                </StyledItem>
              )}
              {!isItemLocked && (
                <Item
                  onClick={({ event }) =>
                    item.type === ExplorerItemType.Folder
                      ? props.onToggleDelete(
                          event as React.MouseEvent<HTMLElement, MouseEvent>,
                          item.name
                        )
                      : props.onToggleFileDelete(
                          event as React.MouseEvent<HTMLElement, MouseEvent>,
                          item.name
                        )
                  }
                >
                  <StyledI title="Delete" className="fas fa-trash"></StyledI>
                  &nbsp; Delete
                </Item>
              )}
            </>
          )}
        </>
      )}
    </Menu>
  );
};
