import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { CustomizableGrid, MetaFieldInfo } from '../components/CustomizableGrid';
import { CSharpTypeCode, MetaObjectType } from '../interfaces/enums';
import { VendorVm } from '../interfaces/interfaces';

import { Tabs, TabLink, TabContent } from '../tabs';
import { VendorAddModal } from './VendorAddModal';
import { actionCreators as globalActions } from '../store/global';
import { VendorsMap } from './VendorsMap';
import { UnvettedPartnerList } from './UnvettedPartnerList';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../store/index';
import { KnownRoles, hasRole } from '../auth/auth';
import { AuthorizationComponent, MyAuthElement } from '../auth/Authorization';
import { vendorsAndSubsSelector } from '../store/vendors/vendors.selectors';
import { VendorsActions } from '../store/vendors/index';
import { GlobalMessageBox } from 'src/components/MessageBox';
import { VendorComplianceReportModal } from './VendorComplianceReportModal';

export const VendorsPage = () => {
  
  const SubsAdminComponent = MyAuthElement([KnownRoles.SubsAdmin]);

  const cols: MetaFieldInfo[] = [
    { displayName: 'Name', propName: 'name', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 4 },
    { displayName: 'Trades', propName: 'trades.tradeName', jsType: 'nested', propType: CSharpTypeCode.Object, columnWidth: 4 },
    { displayName: 'Regions', propName: 'regions.name', jsType: 'nested', propType: CSharpTypeCode.Object, columnWidth: 3 },
    { displayName: 'Labor Type', propName: 'laborType', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 3 },
    { displayName: 'Vendor Type', propName: 'vendorType', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 3 },    
    { displayName: 'Project Count', propName: 'projectCount', jsType: 'number', propType: CSharpTypeCode.Int32, columnWidth: 2 },
    //{ displayName: 'Rating', propName: 'averageRating', jsType: 'number', propType: CSharpTypeCode.Decimal },        
  ]

  const subs: VendorVm[] = useSelector(vendorsAndSubsSelector);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(VendorsActions.getVendorsAndSubs());
  }, []);

  const _lineMap = (vendor: VendorVm) => {
    const colWidth = 20 / cols.length;
    return (
      <NavLink to={`/sub/${vendor.id}`} className='col-sm-12 sv-grid-line' key={vendor.id}>
        {/*{cols.map(c => {*/}
        {/*    <div className={`my-col-${colWidth}`}>{vendor[c.propName]}</div>*/}
        {/*})}*/}
        <div className='my-col-4 sortable truncate'>{vendor.name}</div>
        <div className='my-col-4 sortable truncate'>{vendor.trades.map(x => x.tradeName).join(', ').substring(0, 100)}</div>
        <div className='my-col-3 sortable truncate'>{vendor.regions.map(x => x.name).join(', ').substring(0, 100)}</div>
        <div className='my-col-3 sortable truncate'>{vendor.laborType}</div>
        <div className='my-col-3 sortable truncate'>{vendor.vendorType}</div>
        <div className='my-col-2 sortable '>{vendor.projectCount}</div>
        {/*<div className='my-col-4 sortable '>{vendor.averageRating} / 5</div>*/}
      </NavLink>
    )
  }

  const addVendor = (v: VendorVm) => {
    dispatch(VendorsActions.getVendorsAndSubs());
  }

  return (
    <div className='vendor-page'>
      <GlobalMessageBox />
      <div className='pageHeader'>Subcontractor Management</div>
      <Tabs name='vendor-tabs' onChange={(t: string) => { }}
        renderActiveTabContentOnly={false}>
        <div className='tab-links'>
          <TabLink to='list' component='span'><span>Subcontractors</span></TabLink>
          {/*<TabLink to='list-2' component='span'><span>Subcontractors</span></TabLink>*/}
          <TabLink to='map' component='span'><span>Map</span></TabLink>          
          <SubsAdminComponent componentType="span"><TabLink to='unvetted' component='span'><span>Unvetted Partners</span></TabLink></SubsAdminComponent>
        </div>
        <div className='content'>
          <TabContent for='list'>
            <CustomizableGrid
              type={MetaObjectType.Vendor}
              columns={cols} items={subs}
              mapCallback={_lineMap}
              className='vendor-grid'
              addComponent={<><VendorComplianceReportModal /> &nbsp; <VendorAddModal addCallback={addVendor} /> </>}
              />
          </TabContent>
          {/*<TabContent for='list-2'>*/}
          {/*    <VendorList />*/}
          {/*</TabContent>*/}
          <TabContent for='map'>
            <VendorsMap />
          </TabContent> 
          <SubsAdminComponent>
            <TabContent for='unvetted'>
              <UnvettedPartnerList />
            </TabContent>)
           </SubsAdminComponent>
        </div>
      </Tabs>
    </div>
  )
}