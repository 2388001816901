import * as React from 'react';
import { actionCreators as globalActions } from '../store/global';
import { useDispatch } from 'react-redux';
import { CustomizableGrid, MetaFieldInfo } from '../components/CustomizableGrid';
import { CSharpTypeCode } from '../interfaces/enums';
import { formatDate } from '../helpers/formatters';
import { VendorComplianceField } from '../config/global/VendorComplianceConfig';
import { filter } from '../../node_modules/postcss-merge-longhand/types/lib/decl/index';
import { SliderCheckbox } from '../components/SliderCheckbox';

interface VendorComplianceReportFilters {
  complianceType: number;
  vendorType: string;
  valueCheck: boolean;
  valueText: string;
  expiredOnly: boolean;
  includeInactive: boolean;
}

interface VendorComplianceData {
  vendorId: number,
  vendorName: string,
  vendorType: string,
  fieldId: number,
  fieldType: number,
  entryId: number,
  fieldName: string,
  fieldValue: string,
  expirationDate: string,
  expired: boolean,
  lastModified: string,
}

const VendorComplianceReportColumns: MetaFieldInfo[] = [
  { propName: 'vendorName', displayName: 'Vendor', propType: CSharpTypeCode.String, jsType: 'string', columnWidth: 3 },
  { propName: 'vendorType', displayName: 'Vendor Type', propType: CSharpTypeCode.String, jsType: 'string', columnWidth: 3 },
  { propName: 'fieldName', displayName: 'Compliance', propType: CSharpTypeCode.String, jsType: 'string', columnWidth: 4 },
  { propName: 'fieldValue', displayName: 'Value', propType: CSharpTypeCode.String, jsType: 'string' },
  { propName: 'expirationDate', displayName: 'Exp Date', propType: CSharpTypeCode.DateTime, jsType: 'Date' },
  { propName: 'expired', displayName: 'Expired', propType: CSharpTypeCode.Boolean, jsType: 'boolean' },
  { propName: 'lastModified', displayName: 'Last Modified', propType: CSharpTypeCode.DateTime, jsType: 'Date' },
];

export const VendorComplianceReport: React.FC = () => {
  const [complianceFields, setComplianceFields] = React.useState([] as VendorComplianceField[]);
  const [filters, setFilters] = React.useState({ complianceType: 0, vendorType: '', valueCheck: false, valueText: '', expiredOnly: true, includeInactive: false } as VendorComplianceReportFilters);
  const [working, setWorking] = React.useState(false);
  const [results, setResults] = React.useState([] as VendorComplianceData[]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    _getComplianceFields();
  }, [])

  const _getComplianceFields = () => {
    fetch(`api/globalconfig/vendorcompliance`)
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setComplianceFields(data);
        }
        else {
          dispatch(globalActions.updateMessage(data.message))
        }
      })
  }

  const _getResults = () => {
    setWorking(true);
    fetch(`api/vendor/complianceReport`,
      {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(filters)
      })
      .then(res => Promise.all([res.ok, res.json()]))
      .catch(e => [false, { message: 'Error: An error occurred while processing the request. ' + e } as any])
      // @ts-ignore
      .then(([resOk, data]) => {
        setWorking(false);
        if (resOk) {
          setResults(data);
        }
        else {
          dispatch(globalActions.updateMessage(data.message))
        }
      })
  }

  const _rowMapping = React.useCallback(
    (v: VendorComplianceData) => {
      return (
        <div
          className="sv-grid-line"
          key={v.entryId}
          style={{ cursor: 'default' }}
        >
          <div className="my-col-3">{v.vendorName}</div>
          <div className="my-col-3">{v.vendorType}</div>
          <div className="my-col-4">{v.fieldName}</div>
          <div className="my-col-2">{convertFieldValue(v.fieldType, v.fieldValue)}</div>
          <div className="my-col-2">{v.expirationDate === 'NOT ENTERED' ? v.expirationDate : formatDate(v.expirationDate)}</div>
          <div className="my-col-2">{v.expired ? '\u2713' : ''}</div>
          <div className="my-col-2">{formatDate(v.lastModified)}</div>
        </div>
      );
    },
    [results]);

  return (
    <div className='vendor-compliance-report'>
      <div className='top-row'>
        <div className='report-filters' style={{display: 'flex'}}>
          <div className='my-col-4'>
            <div>Compliance</div>
            <div>
              <select className='form-control' onChange={(e) => setFilters({ ...filters, complianceType: parseInt(e.currentTarget.value) })}>
                <option value='0'>All</option>
                {complianceFields.sort((a,b) => a.fieldName > b.fieldName ? 1 : -1).map(cf => <option key={cf.id} value={cf.id}>{cf.fieldName}</option>)}
              </select>
            </div>
          </div>
          <div className='my-col-4'>
            <div>Vendor Type</div>
            <div>
              <select className='form-control' onChange={(e) => setFilters({ ...filters, vendorType: e.currentTarget.value })}>
                <option value=''></option>
                <option value='Subcontractor'>Subcontractor</option>
                <option value='Material Supplier'>Material Supplier</option>
                <option value='Both'>Both</option>
              </select>
            </div>
          </div>
          <div className='my-col-3' title='Only show expired compliances'>
            <div>Expired Only</div>
            <div>
              <SliderCheckbox fieldName='expiredOnly' selected={filters.expiredOnly} onChange={(e) => setFilters({ ...filters, expiredOnly: e.currentTarget.checked })} />
            </div>
          </div>
          <div className='my-col-3' title='Include inactive vendors in the report'>
            <div>Include Inactive</div>
            <div>
              <SliderCheckbox fieldName='includeInactive' selected={filters.includeInactive} onChange={(e) => setFilters({ ...filters, includeInactive: e.currentTarget.checked })} />
            </div>
          </div>
        </div>
      </div>
      <CustomizableGrid
        columns={VendorComplianceReportColumns}
        items={results}
        mapCallback={_rowMapping}
        loading={working}
        hideLoadingWhenEmpty={true}
        addComponent={<button className='btn btn-x-sm btn-blue' onClick={_getResults}>Run</button>}
      />
    </div>
  );
}

const convertFieldValue = (fieldType: number, fieldValue: string) => {
  if (fieldType === 0) {
    return fieldValue === '1' ? '\u2713' : '';
  }
  if (fieldType === 1) {
    return formatDate(fieldValue)
  }
  return fieldValue;
}