import * as React from 'react';
import { Chart } from 'react-google-charts';
import {
  GoogleChartWrapperChartType,
  GoogleChartWrapper,
  GoogleDataTable,
  GoogleDataTableColumnType,
  // @ts-ignore
} from 'react-google-charts/dist/types';
import * as cx from 'classnames';
import {
  camelize,
  formatDate,
  pascalizeAndSeparate,
  toMoney,
} from '../helpers/formatters';
import Modal from '../components/Modal';
import ShareGraphModal from '../dashboard/ShareGraphModal';
import {
  KPIGraphFormat,
  MetaObjectType,
  metaObjectTypeToString,
} from '../interfaces/enums';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import { actionCreators as graphActionCreators } from '../store/graphs';
import {
  actionCreators as dataStoreActionCreators,
  GraphDataFilters,
} from '../store/graphDataStore';
import { GraphFilterOption } from '../interfaces/interfaces';
import { DashboardScope, UserFilter } from '../dashboard/DashboardGraphs';
import { AddGraphFilterModal } from '../dashboard/AddGraphFilterModal';
import { TableDataDownloadLink } from '../components/TableDataDownloadLink';
import { Loader } from '../components/Loader';
import { PieChartLoader } from '../loaders/PieChartLoader';
import { StandardGridLoader } from '../loaders/StandardGridLoader';
import { formatKPIValue, getNestedPropValue } from './chartHelpers';
import { EChart } from '@kbox-labs/react-echarts';

export interface CustomGraphInfo {
  id: number;
  title: string;
  graphType: GoogleChartWrapperChartType;
  dataType: MetaObjectType;
  dimension: string;
  measure: string;
  measureType: GraphMeasureType;
  tableColumns: GraphTableColumn[];
  filters: UserFilter[];
  appliedFilters: GraphFilterOption[];
  kpiFormat: KPIGraphFormat;
  scope: DashboardScope;
}

export interface GraphTableColumn {
  id: number;
  graphId: number;
  field: string;
  label: string;
  type: string;
}

export enum GraphMeasureType {
  Count,
  Sum,
}

interface Props {
  graphInfo: CustomGraphInfo;
  filterCallback?: (
    field: string,
    value: string,
    dataSource: MetaObjectType
  ) => void;
}

export const KnownGraphTypes = Object.freeze({
  KPI: 'KPI',
  PieChart: 'PieChart',
  Table: 'Table',
});

export const GraphPart = (props: Props) => {
  const { graphInfo, filterCallback } = props,
    {
      title,
      graphType,
      dimension,
      measure,
      measureType,
      tableColumns,
      dataType,
      filters,
      appliedFilters,
      kpiFormat,
    } = graphInfo;
  const chartWrapper = React.useRef<any>();

  const [editTitle, setEditTitle] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showShareModal, setShowShareModal] = React.useState(false);
  const [showAddFilterModal, setShowAddFilterModal] = React.useState(false);

  const metaStore = useSelector((s: ApplicationState) => s.meta);
  const graphStore = useSelector((s: ApplicationState) => s.graphs);
  const graphDataStore = useSelector((s: ApplicationState) => s.graphData);
  const projectStore = useSelector((s: ApplicationState) => s.detail);

  const dispatch = useDispatch();

  const metaObject = metaStore.allObjects.find(
    (x) => x.type === props.graphInfo.dataType
  );

  const metaFields = metaObject ? metaObject.fields : [];

  React.useEffect(() => {
    const serverFilters: GraphDataFilters = {
      projectId: undefined,
      userId: undefined,
    };
    if (graphStore.scope === DashboardScope.Project) {
      if (
        projectStore.detail === undefined ||
        projectStore.detail.id === undefined
      ) {
        return;
      }
      serverFilters.projectId = projectStore.detail.id;
    }

    switch (dataType) {
      case MetaObjectType.Project:
        dispatch(dataStoreActionCreators.getProjects());
        break;
      case MetaObjectType.Vendor:
        dispatch(dataStoreActionCreators.getVendors());
        break;
      case MetaObjectType.Milestone:
        dispatch(dataStoreActionCreators.getMilestones(serverFilters));
        break;
      case MetaObjectType.FieldReport:
        dispatch(dataStoreActionCreators.getFieldReports(serverFilters));
        break;
      case MetaObjectType.RFI:
        dispatch(dataStoreActionCreators.getRfis(serverFilters));
        break;
      case MetaObjectType.Submittal:
        dispatch(dataStoreActionCreators.getSubmittals(serverFilters));
        break;
      case MetaObjectType.Checklist:
        dispatch(dataStoreActionCreators.getChecklists(serverFilters));
        break;
      case MetaObjectType.ChecklistEntry:
        dispatch(dataStoreActionCreators.getChecklistEntries(serverFilters));
        break;
    }
  }, [graphStore.scope, projectStore.detail.id]);

  const _changeGraph = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const prop = e.currentTarget.name;
    dispatch(
      graphActionCreators.updateGraph(
        props.graphInfo.id,
        prop,
        e.currentTarget.value
      )
    );
  };

  const _editTableColumn = (
    e: React.ChangeEvent<HTMLSelectElement>,
    columnId
  ) => {
    dispatch(
      graphActionCreators.updateGraphTableColumn(
        columnId,
        e.currentTarget.value,
        graphInfo.dataType
      )
    );
  };

  const _editTitle = () => {
    const input = document.getElementById(
        `graph-title-${props.graphInfo.id}`
      ) as HTMLInputElement,
      newTitle = input.value;
    dispatch(
      graphActionCreators.updateGraph(props.graphInfo.id, 'title', newTitle)
    );
    setEditTitle(false);
  };

  const _filter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    const field = e.currentTarget.getAttribute('name') || '';
    // @ts-ignore
    dispatch(graphActionCreators.updateGraphFilter(graphInfo.id, field, val));
  };

  const _saveDefaultFilters = () => {
    const inputs = document.querySelectorAll(
      `#graph-part-${graphInfo.id} .filters .user-filter input`
    );
    const values: { filterId; dataSource; field; defaultValue }[] = [];
    // @ts-ignore
    inputs.forEach((i: HTMLInputElement) => {
      values.push({
        filterId: i.getAttribute('data-id'),
        dataSource: i.getAttribute('data-source'),
        field: i.name,
        defaultValue: i.value,
      });
    });

    dispatch(
      graphActionCreators.updateDefaultFilterValues(values, graphInfo.id)
    );
  };

  let data: any[] = [];
  let isProject = false;
  switch (dataType) {
    case MetaObjectType.Project:
      data = graphDataStore.projects;
      isProject = true;
      break;
    case MetaObjectType.Vendor:
      data = graphDataStore.vendors;
      break;
    case MetaObjectType.Milestone:
      data = graphDataStore.milestones;
      break;
    case MetaObjectType.FieldReport:
      data = graphDataStore.fieldReports;
      break;
    case MetaObjectType.RFI:
      data = graphDataStore.rfis;
      break;
    case MetaObjectType.Submittal:
      data = graphDataStore.submittals;
      break;
    case MetaObjectType.Checklist:
      data = graphDataStore.checklists;
      break;
    case MetaObjectType.ChecklistEntry:
      data = graphDataStore.checklistEntries;
      break;
  }
  if (graphStore.filteredProjectIds !== null) {
    data = data.filter((x) => {
      if (!isProject) {
        if (x.project?.id === undefined) return true;
      } else {
        if (x['id'] === undefined) return true;
      }
      // @ts-ignore
      return graphStore.filteredProjectIds.indexOf(x['projectId']) !== -1;
    });
  }

  const typeFilters = graphStore.filterOptions.filter(
    (x) => x.dataSource === dataType
  );

  if (typeFilters.length) data = applyFilters(data, typeFilters);

  if (appliedFilters && appliedFilters.length)
    data = applyFilters(data, appliedFilters);

  const dataPts =
    graphType === KnownGraphTypes.Table
      ? getTableData(data, graphInfo)
      : getPoints(data, graphInfo);

  const formatters: any = graphInfo.tableColumns.map((x, i) => {
    if (x.type === 'date') {
      return {
        type: 'DateFormat' as const,
        column: i,
        options: {
          formatType: 'medium',
        },
      };
    } else return {};
  });

  const options: any = {};
  if (graphType === KnownGraphTypes.Table) options.allowHtml = true;
  if (graphType === KnownGraphTypes.PieChart) options.pieSliceText = 'value';

  let dropdowns: any;
  switch (graphType) {
    //@ts-ignore
    case KnownGraphTypes.KPI:
      dropdowns = (
        <React.Fragment>
          {/*<div>*/}
          {/*    <b>Measure Type</b>*/}
          {/*    <select className='form-control' name='measure' onChange={_changeGraph} defaultValue={measureType}>*/}
          {/*        <option className='form-control' value={GraphMeasureType.Count}>Count</option>*/}
          {/*        <option className='form-control' value={GraphMeasureType.Sum}>Sum</option>*/}
          {/*    </select>*/}
          {/*</div>*/}
          <div>
            <b>Format</b>
            <select
              className="form-control"
              name="kpiFormat"
              onChange={_changeGraph}
              defaultValue={measureType}
            >
              <option className="form-control" value={KPIGraphFormat.Number}>
                Number
              </option>
              <option className="form-control" value={KPIGraphFormat.Money}>
                Money
              </option>
              <option className="form-control" value={KPIGraphFormat.Date}>
                Date
              </option>
            </select>
          </div>
        </React.Fragment>
      );
      break;
    case KnownGraphTypes.PieChart:
      dropdowns = (
        <React.Fragment>
          <div>
            <b>Dimension</b>
            {metaFields && metaFields.length ? (
              <select
                className="form-control"
                name="dimension"
                onChange={_changeGraph}
                defaultValue={dimension}
              >
                <option className="form-control" value=""></option>
                {metaFields.map((x) => (
                  <option
                    className="form-control"
                    key={x.propName}
                    value={x.propName}
                  >
                    {x.displayName}
                  </option>
                ))}
              </select>
            ) : (
              []
            )}
          </div>
          <div>
            <b>Measure Type</b>
            <select
              className="form-control"
              name="measure"
              onChange={_changeGraph}
              defaultValue={measureType}
            >
              <option className="form-control" value={GraphMeasureType.Count}>
                Count
              </option>
              <option className="form-control" value={GraphMeasureType.Sum}>
                Sum
              </option>
            </select>
          </div>
          {measureType === GraphMeasureType.Sum && (
            <div>
              <b>Measure</b>
              <select
                className="form-control"
                name="measure"
                onChange={_changeGraph}
                defaultValue={measure}
              >
                {metaFields
                  .filter((x) => x.jsType === 'number')
                  .map((x) => (
                    <option
                      className="form-control"
                      key={x.propName}
                      value={x.propName}
                    >
                      {x.displayName}
                    </option>
                  ))}
              </select>
            </div>
          )}
        </React.Fragment>
      );
      break;
    case KnownGraphTypes.Table:
      dropdowns =
        tableColumns && tableColumns.length
          ? tableColumns.map((x, i) => (
              <div key={i}>
                <b>Column {i + 1}</b>
                <span
                  className="fas fa-times"
                  onClick={() =>
                    dispatch(graphActionCreators.deleteGraphTableColumn(x.id))
                  }
                  title="Remove Column"
                ></span>
                {metaFields && metaFields.length && (
                  <select
                    className="form-control"
                    onChange={(e) => _editTableColumn(e, x.id)}
                    defaultValue={x.field}
                  >
                    <option value=""></option>
                    {metaFields.map((x) => (
                      <option
                        className="form-control"
                        key={x.propName}
                        value={x.propName}
                      >
                        {x.displayName}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            ))
          : [];
      break;
  }

  const graphOptions = (
    <div
      className={cx(
        'graph-part-options',
        graphType === 'Table' && 'table-options'
      )}
    >
      <div>
        <b>Graph Type</b>
        <select
          className="form-control"
          name="graphType"
          onChange={_changeGraph}
          defaultValue={graphType}
        >
          <option className="form-control" value={KnownGraphTypes.KPI}>
            KPI
          </option>
          <option className="form-control" value={KnownGraphTypes.PieChart}>
            Pie Chart
          </option>
          <option className="form-control" value={KnownGraphTypes.Table}>
            Table
          </option>
        </select>
      </div>
      {dropdowns}
    </div>
  );

  const filterInputs =
    filters && filters.length
      ? filters.map((x, i) => (
          <div key={x.id} className="user-filter">
            <label
              className="truncate"
              title={`${pascalizeAndSeparate(x.label)}`}
            >
              <b>{x.label}</b>
            </label>
            <span
              className="fas fa-times"
              onClick={() =>
                dispatch(graphActionCreators.deleteFilter(x.id, graphInfo.id))
              }
              title="Delete Filter"
            ></span>
            <input
              className="form-control"
              name={camelize(x.field)}
              data-id={x.id}
              data-source={x.dataSource}
              onChange={_filter}
              defaultValue={x.defaultValue}
            />
          </div>
        ))
      : [];

  const deleteModal = (
    <Modal>
      <div className="modal-header">
        <h4>Delete {graphInfo.title}</h4>
      </div>
      <div className="modal-body">
        Are you sure you want to delete this graph?
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-sm btn-danger"
          onClick={() =>
            dispatch(graphActionCreators.deleteGraph(graphInfo.id))
          }
        >
          Delete
        </button>
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={() => setShowDeleteModal(false)}
        >
          Close
        </button>
      </div>
    </Modal>
  );
  const chart = graphDataStore.loading ? (
    //@ts-ignore
    graphType === KnownGraphTypes.PieChart ? (
      <PieChartLoader />
    ) : (
      <StandardGridLoader rowCount={10} rowContentHeight={15} rowPadding={3} />
    )
  ) : //@ts-ignore
  graphType === KnownGraphTypes.KPI ? (
    <div className="graph-kpi-value">
      {formatKPIValue(data.length, kpiFormat)}
    </div>
  ) : graphType === KnownGraphTypes.PieChart ? (
    <EChart
      renderer={'svg'}
      style={{
        height: '350px',
      }}
      tooltip={{
        formatter(params) {
          return `${params.marker}${params.name}<br/><span style="float: left;"><b>${params.data[1]}</b> (${params.percent}%)</span>`;
        },
      }}
      legend={{
        orient: 'vertical',
        right: '5%',
        top: 10,
        icon: 'circle',
        type: 'scroll',
        textStyle: {
          width: 200,
          overflow: 'break',
          fontSize: 13,
          fontFamily: 'Montserrat',
        },
      }}
      title={{
        text: options.title,
        left: 120,
        top: 10,
        textStyle: { fontSize: 11, fontFamily: 'Montserrat' },
      }}
      dataset={{
        source: [...dataPts],
      }}
      series={[
        {
          type: 'pie',
          center: ['45%', '50%'],
          label: {
            position: 'inside',
            formatter: '{@[1]} ',
            fontSize: 14,
          },
        },
      ]}
    />
  ) : (
    <Chart
      key={`${graphType}_${dimension}_${graphInfo.id}`}
      graphID={`${graphType}_${dimension}_${graphInfo.id}`}
      className="graph-part-graph"
      chartType={graphType}
      width={'100%'}
      height={'400px'}
      loader={<Loader loading={true} />}
      data={dataPts}
      options={options}
      formatters={formatters}
      chartEvents={[
        {
          eventName: 'select',
          callback: (args) => {
            const { chartWrapper } = args,
              chart = chartWrapper.getChart(),
              selection = chart.getSelection();
            if (selection.length === 1) {
              const [selectedItem] = selection,
                dataTable = chartWrapper.getDataTable();
              let { column } = selectedItem;
              const { row } = selectedItem;
              if (column == null) {
                if (
                  window.event &&
                  window.event.target &&
                  //@ts-ignore
                  window.event.target.cellIndex
                ) {
                  //@ts-ignore
                  column = window.event.target.cellIndex;
                } else column = 0;
              }
              const value = dataTable
                ? (dataTable.getValue(row, column) || '').toString()
                : '';
              if (filterCallback) {
                const field =
                  graphType === 'Table'
                    ? graphInfo.tableColumns[column].field
                    : graphInfo.dimension;
                filterCallback(field, value, graphInfo.dataType);
              }
            }
          },
        },
      ]}
      getChartWrapper={(w: GoogleChartWrapper) => {
        chartWrapper.current = w;
      }}
    />
  );

  return (
    <div
      id={`graph-part-${graphInfo.id}`}
      className={cx(
        'graph-part',
        //@ts-ignore
        graphType === KnownGraphTypes.KPI && 'kpi-graph'
      )}
      key={`${graphType}_${dimension}_${graphInfo.id}`}
    >
      {editTitle ? (
        <div className="title-edit">
          <input
            id={`graph-title-${graphInfo.id}`}
            className="form-control"
            defaultValue={title}
          />
          <button
            className="btn btn-sm btn-blue fas fa-check"
            onClick={_editTitle}
          ></button>
        </div>
      ) : (
        <h5>
          {title}
          <span
            className="fas fa-edit"
            title="Edit Graph Title"
            onClick={() => setEditTitle(!editTitle)}
          ></span>
          <small style={{ fontSize: '8px', marginLeft: '15px' }}>
            data source: {metaObjectTypeToString(dataType)}
          </small>
        </h5>
      )}
      <div className="graph-buttons">
        {graphType === KnownGraphTypes.Table && (
          <span
            className="fas fa-plus"
            onClick={() =>
              dispatch(graphActionCreators.addGraphTableColumn(graphInfo.id))
            }
            title="Add Column"
          ></span>
        )}
        {graphInfo.graphType === KnownGraphTypes.Table &&
          dataPts.length !== 0 && (
            <TableDataDownloadLink
              key={graphInfo.tableColumns.map((x) => x.field).join('_')}
              data={dataPts}
              filename={graphInfo.title}
            />
          )}
        <span
          className="fa fa-filter"
          onClick={() => setShowAddFilterModal(true)}
          title="Add Filter"
        ></span>
        <span
          className="fa fa-share-alt"
          onClick={() => setShowShareModal(true)}
          title="Share Graph"
        ></span>
        <span
          className="fas fa-trash-alt"
          onClick={() => setShowDeleteModal(true)}
        ></span>
      </div>
      {graphOptions}
      <div className="filters">
        {filterInputs}
        {filterInputs.length ? (
          <button
            className="btn btn-x-sm btn-outline-secondary fas fa-save"
            title="Save default filter values"
            onClick={_saveDefaultFilters}
          ></button>
        ) : (
          []
        )}
      </div>
      {chart}
      {showDeleteModal ? deleteModal : []}
      {showShareModal && (
        <ShareGraphModal
          graphInfo={graphInfo}
          close={(m) => setShowShareModal(false)}
        />
      )}
      {showAddFilterModal ? (
        <AddGraphFilterModal
          graphId={graphInfo.id}
          close={() => setShowAddFilterModal(false)}
        />
      ) : (
        []
      )}
    </div>
  );
};

export const getTableData = (data: any[], graphInfo: CustomGraphInfo) => {
  const { tableColumns } = graphInfo;
  if (!tableColumns || !tableColumns.length) return [];

  const colInfo = tableColumns.map((c) => {
    return { type: c.type, label: c.label };
  });

  const rows = data.map((x) => {
    const row: any[] = [];
    tableColumns.forEach((col) => {
      const levels = col.field.split('.');
      let value: any = x;
      for (let i = 0; i < levels.length; ++i) {
        const currentLevel = camelize(levels[i]);
        const isFinal = i === levels.length - 1;

        value = Array.isArray(value)
          ? isFinal
            ? value.map((x) => x[currentLevel]).join(', ')
            : value.map((x) => x[currentLevel])
          : value[currentLevel] || 'N/A';

        if (isFinal) {
          if (col.type === 'date') {
            if (!isNaN(Date.parse(value))) {
              row.push({ v: new Date(value), f: null });
            } else row.push({ v: new Date(1970, 0, 1), f: null });
          } else row.push(value.toString());
          //else
        }
      }
    });
    return row;
  });

  rows.unshift(colInfo);

  return rows;
};

export const getPoints = (data: any[], graphInfo: CustomGraphInfo) => {
  if (graphInfo.measureType === GraphMeasureType.Sum) {
    if (graphInfo.measure == null || graphInfo.measure === '') return [];
    const ptCount = data.reduce((p, c) => {
      const name = getNestedPropValue(c, graphInfo.dimension);
      //var name = c[dimensionStr] != null ? c[dimensionStr] : 'Unknown';
      if (!Object.prototype.hasOwnProperty.call(p, name)) {
        p[name] = 0;
      }
      p[name] += c[getNestedPropValue(c, graphInfo.measure)];
      return p;
    }, {});

    const dataPts = Object.keys(ptCount).map((x) => {
      return [x, ptCount[x]];
    });

    dataPts.unshift([graphInfo.dimension, 'Sum']);
    return dataPts;
  } else {
    const ptCount = data.reduce((p, c) => {
      const name = getNestedPropValue(c, graphInfo.dimension);
      //var name = c[dimensionStr] != null ? c[dimensionStr] : 'Unknown';
      //if (name !== undefined) {
      if (!Object.prototype.hasOwnProperty.call(p, name)) {
        p[name] = 0;
      }
      p[name]++;
      return p;
      //}
    }, {});

    const dataPts = Object.keys(ptCount).map((x) => {
      return [x, ptCount[x]];
    });

    dataPts.unshift([
      graphInfo.dimension,
      graphInfo.measureType === GraphMeasureType.Count ? 'Count' : 'Sum',
    ]);

    return dataPts;
  }
};

const applyFilters = (data: any[], filters: GraphFilterOption[]) => {
  filters.forEach((f) => {
    data = data.filterByNestedProp(f.prop, f.value.toString());
  });

  return data;
};
