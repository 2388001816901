import { AppStartListening } from '../listenerMiddleware';
import { VendorsActions } from './vendors.reducer';
import { getFedCertTypes, getVendorsAndSubs } from '../../services/vendors';

export const addVendorsListeners = (startListening: AppStartListening) => {
  const listeners = [
    startListening({
      actionCreator: VendorsActions.getVendorsAndSubs,
      effect: async (action, listenApi) => {
        const records = await getVendorsAndSubs();

        if (records) {
          listenApi.dispatch(
            VendorsActions.setVendorsAndSubcs({ records: records })
          );
        }
      },
    }),
    startListening({
      actionCreator: VendorsActions.getVendorFedCertTypes,
      effect: async (action, listenApi) => {
        const types = await getFedCertTypes();
        if (types) {
          listenApi.dispatch(VendorsActions.setVendorFedCertTypes(types));
        }
      },
    }),
  ];
  return () => {
    listeners.forEach((unsubscribe) => unsubscribe());
  };
};
