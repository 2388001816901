import React, { useMemo, useState } from 'react';
import { Modal2 } from 'src/components/Modal';
import { useSelector } from 'react-redux';
import { fedCertTypesSelector } from 'src/store/vendors';
import { FederationCertificationType } from 'src/interfaces/interfaces';
import { FormGroupDiv } from 'src/styledComponents';

interface VendorFedCertAddModalProps {
  availableCertTypes: FederationCertificationType[];
  onModalClose: () => void;
  onAddCert: (type: number, file: File) => void;
}

export const VendorFedCertAddModal = ({
  availableCertTypes,
  onModalClose,
  onAddCert,
}: VendorFedCertAddModalProps) => {
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const canUpload = useMemo(() => {
    return selectedOption > 0 && selectedFile !== null;
  }, [selectedOption, selectedFile]);
  return (
    <Modal2 dialogStyle={{ width: '550px' }}>
      <div className="modal-header">
        <h4>Upload Federation Certification</h4>
      </div>
      <div className="modal-body">
        <FormGroupDiv style={{ marginBottom: '16px' }}>
          <select
            onChange={(e) => setSelectedOption(+e.target.value)}
            className="form-control"
          >
            <option value={0}>Select Certification Type</option>
            {availableCertTypes.map((certType) => (
              <option key={certType.id} value={certType.id}>
                {certType.name}
              </option>
            ))}
          </select>
        </FormGroupDiv>
        <FormGroupDiv>
          <input
            type={'file'}
            accept=".pdf"
            onChange={(e) =>
              setSelectedFile(e.target.files?.length ? e.target.files[0] : null)
            }
          />
        </FormGroupDiv>
      </div>
      <div className="modal-footer">
        <button
          disabled={!canUpload}
          className="btn btn-sm btn-blue"
          onClick={() => {
            selectedFile && onAddCert(selectedOption, selectedFile);
            onModalClose();
          }}
        >
          Upload
        </button>
        <span
          className="btn btn-sm btn-outline-secondary"
          onClick={onModalClose}
        >
          Close
        </span>
      </div>
    </Modal2>
  );
};
