
import * as React from 'react';
import Modal, { Modal2 } from '../components/Modal';

import { UnvettedPartner, F40Form, F40FormSafetyPerformance, F40FormSafetyTopics } from '../interfaces/interfaces';
import { formatDateTime } from '../helpers/formatters';
import { RejectPartnerPhaseTwoFormModal, ReviewPartnerPhaseTwoFormModal } from './ReviewPartnerPhaseTwoFormModal';
import { KnownRoles, hasRole } from '../auth/auth';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store/index';

export const F40Detail = (props: { partner: UnvettedPartner, refresh: () => void }) => {

  const { partner } = props,
    form = partner.f40Form || null;
  const userStore = useSelector((s: ApplicationState) => s.user);

  const [popupMessage, setPopupMessage] = React.useState(null as string | null);
  const [showReviewModal, setShowReviewModal] = React.useState(false);
  const [showRejectionModal, setShowRejectionModal] = React.useState(false);

  React.useEffect(() => {
  }, [props.partner]);

  React.useEffect(() => {

  }, [form])

  const popupMessageComponent = popupMessage ? <Modal2>
    <div className='modal-body'>{popupMessage}</div>
    <div className='modal-footer'><button className='btn btn-sm btn-outline-secondary' style={{ color: 'black' }} onClick={() => setPopupMessage(null)}>Close</button></div>
  </Modal2> : []


  const formComponent = form ? <div className='f40-form'>
    <h2 style={{ textAlign: 'center' }}>Contractor Safety Qualification Form (CQSF)</h2>
    {form.isSubmitted && <div>
      <p style={{marginBottom: '5px'}}>
        Submitted on {formatDateTime(form.submittedDate)}
      </p>
    </div>}
    {form.isReviewed && <div>
      <p style={{ marginBottom: '5px' }}>
        Reviewed by <b>{form.reviewerName}</b> on {formatDateTime(form.reviewedDate)}
      </p>
    </div>}
    <F40FormSection title='Section 1 - General'>
      <F40FormRow>
        <F40FormGroup label='Company Name' width='100%'>
          <input disabled value={form.companyName} /></F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup label='Address' width='33%'>
          <input disabled value={form.address} /></F40FormGroup>
        <F40FormGroup label='City' width='33%'>
          <input disabled value={`${form.city}`} /></F40FormGroup>
        <F40FormGroup label='State/Province' width='33%'>
          <input disabled value={`${form.stateProvince}`} /></F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup label='Zip/Postal Code' width='33%'>
          <input disabled value={form.zip} /></F40FormGroup>
        <F40FormGroup label='Country' width='33%'>
          <input disabled value={form.country} /></F40FormGroup>
        <F40FormGroup label='Phone' width='33%'>
          <input disabled value={form.phone} /></F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <p><b>Indicate how many employees you employee (appx)</b></p>
        <F40FormGroup label='Full-Time' width='33%'>
          <input disabled type='number' name='fullTimeEmployees' value={form.fullTimeEmployees} />
        </F40FormGroup>
        <F40FormGroup label='Part-Time' width='33%'>
          <input disabled type='number' name='partTimeEmployees' value={form.partTimeEmployees} />
        </F40FormGroup>
        <F40FormGroup label='Seasonal' width='33%'>
          <input disabled type='number' name='seasonalEmployees' value={form.seasonalEmployees} />
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%' noFlex={true}>
          <p><b>Describe the nature of business your organization typically performs</b></p>
          <textarea disabled rows={4} name='natureOfBusiness' value={form.natureOfBusiness} />
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup noFlex={true} width={'33%'}>
          <p><b>Are you a division of a corporation?</b></p>
          <input disabled type='radio' name='isDivisionOfCorporation' checked={form.isDivisionOfCorporation} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='isDivisionOfCorporation' checked={!form.isDivisionOfCorporation} /> No
        </F40FormGroup>
      </F40FormRow>
      {form.isDivisionOfCorporation && <>
        <F40FormRow>
          <p><b>Parent Corporation Address:</b></p>
          <F40FormGroup label='Address' width='33%'>
            <input disabled name='parentCorpAddress1' value={form.parentCorpAddress1} /></F40FormGroup>
          <F40FormGroup label='City' width='33%'>
            <input disabled name='parentCorpCity' value={`${form.parentCorpCity}`} /></F40FormGroup>
          <F40FormGroup label='State' width='33%'>
            <input disabled name='parentCorpStateProvince' value={form.parentCorpStateProvince} />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label='Zip/Postal Code' width='33%'>
            <input disabled name='parentCorpZip' value={form.parentCorpZip} /></F40FormGroup>
          <F40FormGroup label='Country' width='33%'>
            <input disabled name='parentCorpCountry' value={form.parentCorpCountry} /></F40FormGroup>
        </F40FormRow>
      </>}
      <F40FormRow>
        <F40FormGroup noFlex={true} width={'33%'}>
          <p><b>Are you self-insured for Worker’s Compensation insurance?</b></p>
          <input disabled type='radio' name='isSelfInsured' checked={form.isSelfInsured} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='isSelfInsured' checked={!form.isSelfInsured} data-bool-value={false} /> No
        </F40FormGroup>
        {!form.isSelfInsured &&
          <F40FormGroup width='66%' label='Name of your third-party insurance organization'>
            <input disabled name='thirdPartyInsurer' value={form.thirdPartyInsurer} />
          </F40FormGroup>
        }
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%' noFlex>
          <p><b>Does your company use subcontractors?</b></p>
          <input disabled type='radio' name='usesSubcontractors' checked={form.usesSubcontractors} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='usesSubcontractors' checked={!form.usesSubcontractors} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <p><b>Form completed by:</b></p>
        <F40FormGroup width='33%' label='Name'>
          <input disabled name='submitterName' value={form.submitterName} />
        </F40FormGroup>
        <F40FormGroup width='33%' label='Title'>
          <input disabled name='submitterTitle' value={form.submitterTitle} />
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='33%' label='Email'>
          <input disabled name='submitterEmail' value={form.submitterEmail} />
        </F40FormGroup>
        <F40FormGroup width='33%' label='Phone'>
          <input disabled name='submitterPhone' value={form.submitterPhone} />
        </F40FormGroup>
      </F40FormRow>
    </F40FormSection>
    <F40FormSection title="Section 2 - Health & Safety Information">
      <F40FormRow>
        <F40FormGroup noFlex width='100%'>
          <p><b>Do you have a dedicated fulltime health & safety professional within your company?</b></p>
          <input disabled type='radio' name='hasHealthSafetyProfessional' checked={form.hasHealthSafetyProfessional} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='hasHealthSafetyProfessional' checked={!form.hasHealthSafetyProfessional} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <p><b>Who is responsible for health & safety within your organization?</b></p>
        <F40FormGroup width='33%' label='Name/Title'>
          <input disabled name='healthSafetyProName' value={form.healthSafetyProName} />
        </F40FormGroup>
        <F40FormGroup width='33%' label='Phone'>
          <input disabled name='healthSafetyProPhone' value={form.healthSafetyProPhone} />
        </F40FormGroup>
        <F40FormGroup width='33%' label='Location'>
          <input disabled name='healthSafetyProLocation' value={form.healthSafetyProLocation} />
        </F40FormGroup>
      </F40FormRow>
    </F40FormSection>
    <F40FormSection title='Section 3 – Health & Safety Programs and Procedures'>
      <F40FormRow>
        <F40FormGroup width='50%'>
          <b>Does your company have a written health & safety program?</b>
          <input disabled type='radio' name='hasSafetyProgram' checked={form.hasSafetyProgram} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='hasSafetyProgram' checked={!form.hasSafetyProgram} data-bool-value={false} /> No
        </F40FormGroup>
        <F40FormGroup width='50%'>
          <b>Does your written Safety program cover all types of work activities which you will perform?</b>
          <input disabled type='radio' name='doesSafetyProgramCoverAllActivities' checked={form.doesSafetyProgramCoverAllActivities} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='doesSafetyProgramCoverAllActivities' checked={!form.doesSafetyProgramCoverAllActivities} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <p><b>Does your SAFETY program address the following elements:</b></p>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b>Management commitment and expectations</b>
          <input disabled type='radio' name='coveredMgmtExpectations' checked={form.coveredMgmtExpectations} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='coveredMgmtExpectations' checked={!form.coveredMgmtExpectations} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b>Accountabilities and responsibilities for managers, supervisors, and employees</b>
          <input disabled type='radio' name='coveredAccountabilities' checked={form.coveredAccountabilities} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='coveredAccountabilities' checked={!form.coveredAccountabilities} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b>Hazard recognition and control</b>
          <input disabled type='radio' name='coveredHazardControl' checked={form.coveredHazardControl} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='coveredHazardControl' checked={!form.coveredHazardControl} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b>Workplace Analysis</b>
          <input disabled type='radio' name='coveredWorkplaceAnalysis' checked={form.coveredWorkplaceAnalysis} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='coveredWorkplaceAnalysis' checked={!form.coveredWorkplaceAnalysis} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b>Safety Training</b>
          <input disabled type='radio' name='coveredSafetyTraining' checked={form.coveredSafetyTraining} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='coveredSafetyTraining' checked={!form.coveredSafetyTraining} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b>Incident reporting and investigation, including near misses</b>
          <input disabled type='radio' name='coveredIncidentReporting' checked={form.coveredIncidentReporting} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='coveredIncidentReporting' checked={!form.coveredIncidentReporting} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <p><b>Does your written S&H Program contain the following programs/topics? Identify those topics that your work will fall under.</b></p>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='50%'>
          <b style={{ width: '250px ' }}>Confined Space</b>
          <input disabled type='radio' name='safetyTopics.confinedSpaces' checked={form.safetyTopics.confinedSpaces === 'Yes'} data-value='Yes' /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.confinedSpaces' checked={form.safetyTopics.confinedSpaces === 'No'} data-value='No' /> No &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.confinedSpaces' checked={form.safetyTopics.confinedSpaces === 'N/A'} data-value='N/A' /> N/A
        </F40FormGroup>
        <F40FormGroup width='50%'>
          <b style={{ width: '250px ' }}>Material Handling</b>
          <input disabled type='radio' name='safetyTopics.materialHandling' checked={form.safetyTopics.materialHandling === 'Yes'} data-value='Yes' /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.materialHandling' checked={form.safetyTopics.materialHandling === 'No'} data-value='No' /> No &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.materialHandling' checked={form.safetyTopics.materialHandling === 'N/A'} data-value='N/A' /> N/A
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='50%'>
          <b style={{ width: '250px ' }}>Control of Hazardous Energy LOTO</b>
          <input disabled type='radio' name='safetyTopics.controlHazardEnergy' checked={form.safetyTopics.controlHazardEnergy === 'Yes'} data-value='Yes' /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.controlHazardEnergy' checked={form.safetyTopics.controlHazardEnergy === 'No'} data-value='No' /> No &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.controlHazardEnergy' checked={form.safetyTopics.controlHazardEnergy === 'N/A'} data-value='N/A' /> N/A
        </F40FormGroup>
        <F40FormGroup width='50%'>
          <b style={{ width: '250px ' }}>Scaffolding</b>
          <input disabled type='radio' name='safetyTopics.scaffolding' checked={form.safetyTopics.scaffolding === 'Yes'} data-value='Yes' /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.scaffolding' checked={form.safetyTopics.scaffolding === 'No'} data-value='No' /> No &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.scaffolding' checked={form.safetyTopics.scaffolding === 'N/A'} data-value='N/A' /> N/A
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='50%'>
          <b style={{ width: '250px ' }}>Fire Prevention & Protection</b>
          <input disabled type='radio' name='safetyTopics.firePrevention' checked={form.safetyTopics.firePrevention === 'Yes'} data-value='Yes' /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.firePrevention' checked={form.safetyTopics.firePrevention === 'No'} data-value='No' /> No &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.firePrevention' checked={form.safetyTopics.firePrevention === 'N/A'} data-value='N/A' /> N/A
        </F40FormGroup>
        <F40FormGroup width='50%'>
          <b style={{ width: '250px ' }}>Fall Protection</b>
          <input disabled type='radio' name='safetyTopics.fallProtection' checked={form.safetyTopics.fallProtection === 'Yes'} data-value='Yes' /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.fallProtection' checked={form.safetyTopics.fallProtection === 'No'} data-value='No' /> No &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.fallProtection' checked={form.safetyTopics.fallProtection === 'N/A'} data-value='N/A' /> N/A
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='50%'>
          <b style={{ width: '250px ' }}>Personal Protective Equipment</b>
          <input disabled type='radio' name='safetyTopics.ppe' checked={form.safetyTopics.ppe === 'Yes'} data-value='Yes' /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.ppe' checked={form.safetyTopics.ppe === 'No'} data-value='No' /> No &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.ppe' checked={form.safetyTopics.ppe === 'N/A'} data-value='N/A' /> N/A
        </F40FormGroup>
        <F40FormGroup width='50%'>
          <b style={{ width: '250px ' }}>Rigging / Cranes</b>
          <input disabled type='radio' name='safetyTopics.riggingAndCranes' checked={form.safetyTopics.riggingAndCranes === 'Yes'} data-value='Yes' /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.riggingAndCranes' checked={form.safetyTopics.riggingAndCranes === 'No'} data-value='No' /> No &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.riggingAndCranes' checked={form.safetyTopics.riggingAndCranes === 'N/A'} data-value='N/A' /> N/A
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='50%'>
          <b style={{ width: '250px ' }}>Hot Work / Welding Safety</b>
          <input disabled type='radio' name='safetyTopics.weldingSafety' checked={form.safetyTopics.weldingSafety === 'Yes'} data-value='Yes' /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.weldingSafety' checked={form.safetyTopics.weldingSafety === 'No'} data-value='No' /> No &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.weldingSafety' checked={form.safetyTopics.weldingSafety === 'N/A'} data-value='N/A' /> N/A
        </F40FormGroup>
        <F40FormGroup width='50%'>
          <b style={{ width: '250px ' }}>Excavation & Trenching</b>
          <input disabled type='radio' name='safetyTopics.excavationAndTrenches' checked={form.safetyTopics.excavationAndTrenches === 'Yes'} data-value='Yes' /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.excavationAndTrenches' checked={form.safetyTopics.excavationAndTrenches === 'No'} data-value='No' /> No &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.excavationAndTrenches' checked={form.safetyTopics.excavationAndTrenches === 'N/A'} data-value='N/A' /> N/A
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='50%'>
          <b style={{ width: '250px ' }}>Hand & Powered Tools</b>
          <input disabled type='radio' name='safetyTopics.handAndPoweredTools' checked={form.safetyTopics.handAndPoweredTools === 'Yes'} data-value='Yes' /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.handAndPoweredTools' checked={form.safetyTopics.handAndPoweredTools === 'No'} data-value='No' /> No &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.handAndPoweredTools' checked={form.safetyTopics.handAndPoweredTools === 'N/A'} data-value='N/A' /> N/A
        </F40FormGroup>
        <F40FormGroup width='50%'>
          <b style={{ width: '250px ' }}>Emergency Action Plans</b>
          <input disabled type='radio' name='safetyTopics.emergencyActionPlans' checked={form.safetyTopics.emergencyActionPlans === 'Yes'} data-value='Yes' /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.emergencyActionPlans' checked={form.safetyTopics.emergencyActionPlans === 'No'} data-value='No' /> No &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.emergencyActionPlans' checked={form.safetyTopics.emergencyActionPlans === 'N/A'} data-value='N/A' /> N/A
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='50%'>
          <b style={{ width: '250px ' }}>Respiratory Protection</b>
          <input disabled type='radio' name='safetyTopics.respiratoryProtection' checked={form.safetyTopics.respiratoryProtection === 'Yes'} data-value='Yes' /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.respiratoryProtection' checked={form.safetyTopics.respiratoryProtection === 'No'} data-value='No' /> No &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.respiratoryProtection' checked={form.safetyTopics.respiratoryProtection === 'N/A'} data-value='N/A' /> N/A
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b style={{ width: '700px ' }}>Do you have employees trained in First Aid/AED/CPR? </b>
          <input disabled type='radio' name='safetyTopics.hasFirstAidTrainedEmployees' checked={form.safetyTopics.hasFirstAidTrainedEmployees} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.hasFirstAidTrainedEmployees' checked={!form.safetyTopics.hasFirstAidTrainedEmployees} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b style={{ width: '700px ' }}>Do you have a drug and alcohol testing policy? </b>
          <input disabled type='radio' name='safetyTopics.hasDrugTestingPolicy' checked={form.safetyTopics.hasDrugTestingPolicy} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.hasDrugTestingPolicy' checked={!form.safetyTopics.hasDrugTestingPolicy} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b style={{ width: '700px ' }}>Do you employ persons with a Commercial Driver’s License (CDL)? </b>
          <input disabled type='radio' name='safetyTopics.hasCDLEmployees' checked={form.safetyTopics.hasCDLEmployees} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.hasCDLEmployees' checked={!form.safetyTopics.hasCDLEmployees} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='40%'>
          <b style={{ width: '400px ' }}>Do you hold site safety meetings? </b>
          <input disabled type='radio' name='safetyTopics.holdsSiteSafetyMeetings' checked={form.safetyTopics.holdsSiteSafetyMeetings} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.holdsSiteSafetyMeetings' checked={!form.safetyTopics.holdsSiteSafetyMeetings} data-bool-value={false} /> No
        </F40FormGroup>
        <F40FormGroup width='60%' label='Frequency of the meetings' labelWidth='300px'>
          <input disabled name='safetyTopics.frequencyOfSafetyMeetings' value={form.safetyTopics.frequencyOfSafetyMeetings} />
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='40%'>
          <b style={{ width: '400px ' }}>Do you conduct health & safety inspections/audits?</b>
          <input disabled type='radio' name='safetyTopics.conductsSafetyAudits' checked={form.safetyTopics.conductsSafetyAudits} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.conductsSafetyAudits' checked={!form.safetyTopics.conductsSafetyAudits} data-bool-value={false} /> No
        </F40FormGroup>
        <F40FormGroup width='60%' label='Frequency of the meetings' labelWidth='300px'>
          <input disabled name='safetyTopics.safetyAuditsFrequency' value={form.safetyTopics.safetyAuditsFrequency} />
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b style={{ width: '700px ' }}>Do you have a disciplinary action process for addressing employee health & safety performance?</b>
          <input disabled type='radio' name='safetyTopics.hasDisciplinaryProcess' checked={form.safetyTopics.hasDisciplinaryProcess} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.hasDisciplinaryProcess' checked={!form.safetyTopics.hasDisciplinaryProcess} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b style={{ width: '800px ' }}>If you use subcontractors, do you use health & safety performance criteria in the selection of subcontractors?</b>
          <input disabled type='radio' name='safetyTopics.usesSafetyCriteriaForSubs' checked={form.safetyTopics.usesSafetyCriteriaForSubs === 'Yes'} data-value='Yes' /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.usesSafetyCriteriaForSubs' checked={form.safetyTopics.usesSafetyCriteriaForSubs === 'No'} data-value='No' /> No &nbsp; &nbsp;
          <input disabled type='radio' name='safetyTopics.usesSafetyCriteriaForSubs' checked={form.safetyTopics.usesSafetyCriteriaForSubs === 'N/A'} data-value='N/A' /> N/A
        </F40FormGroup>
      </F40FormRow>
    </F40FormSection>
    <F40FormSection title='Section 4 - S&H Training'>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b style={{ width: '700px ' }}>Do you perform New Employee S&H Orientation Training?</b>
          <input disabled type='radio' name='hasSHOrientationTraining' checked={form.hasSHOrientationTraining} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='hasSHOrientationTraining' checked={!form.hasSHOrientationTraining} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b style={{ width: '700px ' }}>Do you provide the necessary and required S&H training for your employees?</b>
          <input disabled type='radio' name='hasSHTraining' checked={form.hasSHTraining} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='hasSHTraining' checked={!form.hasSHTraining} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b style={{ width: '700px ' }}>Are employees test for this comprehension of the training materials?</b>
          <input disabled type='radio' name='hasSHTrainingTests' checked={form.hasSHTrainingTests} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='hasSHTrainingTests' checked={!form.hasSHTrainingTests} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b style={{ width: '700px ' }}>Is all S&H Training documented?</b>
          <input disabled type='radio' name='hasSHTrainingDocumentation' checked={form.hasSHTrainingDocumentation} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='hasSHTrainingDocumentation' checked={!form.hasSHTrainingDocumentation} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b style={{ width: '700px ' }}>Are employees certified/qualified to operate all machinery and equipment they are asked to?</b>
          <input disabled type='radio' name='hasCertsForAllMachineryOperators' checked={form.hasCertsForAllMachineryOperators} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='hasCertsForAllMachineryOperators' checked={!form.hasCertsForAllMachineryOperators} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
    </F40FormSection>
    <F40FormSection title='Section 5 - Job Safety'>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b style={{ width: '700px ' }}>Are job observations, such as job safety analysis (JSA) conducted?</b>
          <input disabled type='radio' name='hasJobSafetyAnalysis' checked={form.hasJobSafetyAnalysis} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='hasJobSafetyAnalysis' checked={!form.hasJobSafetyAnalysis} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b style={{ width: '700px ' }}>Are procedures for critical jobs written and reviewed with the employees?</b>
          <input disabled type='radio' name='proceduresWrittenAndReviewed' checked={form.proceduresWrittenAndReviewed === 'Yes'} data-value='Yes' /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='proceduresWrittenAndReviewed' checked={form.proceduresWrittenAndReviewed === 'No'} data-value='No' /> No &nbsp; &nbsp;
          <input disabled type='radio' name='proceduresWrittenAndReviewed' checked={form.proceduresWrittenAndReviewed === 'N/A'} data-value='N/A' /> N/A
        </F40FormGroup>
      </F40FormRow>
    </F40FormSection>
    <F40FormSection title='Section 6 - Health & Safety Performance'>
      <F40FormRow>
        <p><b>List your company’s Workers’ Compensation Experience Modification Rate (EMR) for the three (3) most recent years:</b></p>
      </F40FormRow>
      <F40FormRow>
        {form.safetyPerformanceYears.map((x, i) => (
          <F40FormGroup key={i} width='33%' label={`${x.year} EMR`}>
            <input disabled type='number' name='emr' value={x.emr} data-index={i} />
          </F40FormGroup>
        ))}
      </F40FormRow>
      <F40FormRow>
        <p><b>Use your OSHA 300 log (or equivalent) to record the number of injuries and illnesses for the last three (3) years:</b></p>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='30%' label='Year' />
        {form.safetyPerformanceYears.map((x, i) => (
          <F40FormGroup key={i} width='20%' label={`${x.year}`} />
        ))}
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='30%' label='Number of Labor Hours Worked' labelWidth='400px' />
        {form.safetyPerformanceYears.map((x, i) => (
          <F40FormGroup key={i} width='20%'><input disabled name='laborHoursWorked' type='number' value={x.laborHoursWorked} data-index={i} /></F40FormGroup>
        ))}
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='30%' label='Average Number of Employees on Your Payroll' labelWidth='400px' />
        {form.safetyPerformanceYears.map((x, i) => (
          <F40FormGroup key={i} width='20%'><input disabled name='averageEmployeesOnPayroll' type='number' value={x.averageEmployeesOnPayroll} data-index={i} /></F40FormGroup>
        ))}
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='30%' label='Number of Fatalities' labelWidth='400px' />
        {form.safetyPerformanceYears.map((x, i) => (
          <F40FormGroup key={i} width='20%'><input disabled name='numberOfFatalities' type='number' value={x.numberOfFatalities} data-index={i} /></F40FormGroup>
        ))}
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='30%' label='Number of OSHA Recordable Cases' labelWidth='400px' />
        {form.safetyPerformanceYears.map((x, i) => (
          <F40FormGroup key={i} width='20%'><input disabled name='numberOfRecordableCases' type='number' value={x.numberOfRecordableCases} data-index={i} /></F40FormGroup>
        ))}
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='30%' label='Number of OSHA Restricted Only Cases' labelWidth='400px' />
        {form.safetyPerformanceYears.map((x, i) => (
          <F40FormGroup key={i} width='20%'><input disabled name='numberOfRestrictedCases' type='number' value={x.numberOfRestrictedCases} data-index={i} /></F40FormGroup>
        ))}
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='30%' label='Number of OSHA Lost Time Cases' labelWidth='400px' />
        {form.safetyPerformanceYears.map((x, i) => (
          <F40FormGroup key={i} width='20%'><input disabled name='numberOfLostTimeCases' type='number' value={x.numberOfLostTimeCases} data-index={i} /></F40FormGroup>
        ))}
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='30%' label='OSHA Recordable Incident Rate' labelWidth='400px' />
        {form.safetyPerformanceYears.map((x, i) => (
          <F40FormGroup key={i} width='20%'><input disabled name='recordableIncidentRate' type='number' value={x.recordableIncidentRate} data-index={i} /></F40FormGroup>
        ))}
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='30%' label='OSHA Restricted Only Incident Rate' labelWidth='400px' />
        {form.safetyPerformanceYears.map((x, i) => (
          <F40FormGroup key={i} width='20%'><input disabled name='restrictedOnlyIncidentRate' type='number' value={x.restrictedOnlyIncidentRate} data-index={i} /></F40FormGroup>
        ))}
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='30%' label='OSHA Lost Time Incident Rate' labelWidth='400px' />
        {form.safetyPerformanceYears.map((x, i) => (
          <F40FormGroup key={i} width='20%'><input disabled name='lostTimeIncidentRate' type='number' value={x.lostTimeIncidentRate} data-index={i} /></F40FormGroup>
        ))}
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='100%'>
          <b style={{ width: '700px ' }}>Has your company received any OSHA citations within the last five (5) years?</b>
          <input disabled type='radio' name='hasReceivedOSHACitations' checked={form.hasReceivedOSHACitations} /> Yes &nbsp; &nbsp;
          <input disabled type='radio' name='hasReceivedOSHACitations' checked={!form.hasReceivedOSHACitations} data-bool-value={false} /> No
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup noFlex label='Attachments' width='100%'>
          {form.attachments.map(x => {
            const fileUri = x.filePath.split('wwwroot')[x.filePath.split('wwwroot').length - 1];
            const filePath = 'https://partner-registration.core-states.com' + fileUri;
            return <div key={x.id}><a href={filePath} target="_blank">{x.fileName}</a></div>
          })}
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup width='50%' label='Signature'>
          <input disabled name='digitalSignature' value={form.digitalSignature} />
        </F40FormGroup>
        <F40FormGroup width='50%' label='Title'>
          <input disabled name='digitalSignatureTitle' value={form.digitalSignatureTitle} />
        </F40FormGroup>
      </F40FormRow>
    </F40FormSection>
    {(hasRole(userStore.user, [KnownRoles.SubsAdmin]) && form.isSubmitted && !form.isReviewed) ? <F40FormRow>
      <button className='btn btn-sm btn-green' onClick={() => setShowReviewModal(true)}>Mark as Reviewed</button>
    </F40FormRow> : []}
    {(hasRole(userStore.user, [KnownRoles.SubsAdmin]) && form.isSubmitted) ? <F40FormRow>
      <button className='btn btn-sm btn-red' onClick={() => setShowRejectionModal(true)}>Reject</button>
    </F40FormRow> : []}
  </div> : <div style={{ /*backgroundColor: 'rgb(230, 230, 230)'*/ }}><h4>{partner.vendorName} has not started this form yet</h4></div>;

  return <div className='f40-page'>
    {showReviewModal && <ReviewPartnerPhaseTwoFormModal partnerId={partner.id} formType='F40' close={(callRefresh: boolean) => { if (callRefresh) { props.refresh(); } setShowReviewModal(false) }} />}
    {showRejectionModal && <RejectPartnerPhaseTwoFormModal partnerId={partner.id} formType='F40' close={(callRefresh: boolean) => { if (callRefresh) { props.refresh(); } setShowRejectionModal(false) }} />}
    {popupMessageComponent}
    {formComponent}
  </div>
}

export const F40FormSection = ({ children, title }: React.PropsWithChildren<any>) => (
  <div className='f40-form-section'>
    <div className='section-header'>
      <h3>{title}</h3>
    </div>
    <div className='section-body'>
      {children}
    </div>
  </div>
)

export const F40FormRow = ({ children, title }: React.PropsWithChildren<any>) => (
  <div className='f40-form-row'>
    {children}
  </div>
)

export const F40FormGroup = ({ children, label, width, noFlex, labelWidth, style }: React.PropsWithChildren<{ label?: string | undefined, width: string, noFlex?: boolean, labelWidth?: string, style?: object }>) => (
  <div className='f40-form-group form-group' style={{ ...style, display: noFlex ? 'inline-block' : 'inline-flex', width: width }}>
    {label && <b className='form-label' style={labelWidth ? { width: labelWidth } : {}}>{label}</b>}
    {children}
  </div>
)