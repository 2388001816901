import { api } from '../api';
import { AxiosError, AxiosResponse } from 'axios';
import { partnerRegSecret } from 'src/vendors/PartnerRegistrationConstants';

export const getPartnerRegKeyFromVault = async () => {
  try {
    const response: AxiosResponse<string> = await api.get(
      `api/keyvault/getsecret?secretName=${partnerRegSecret}`,
      {
        //header: {
        //  'Metadata': 'true'
        //}
      }
    );

    if (response.status == 200) {
      return response.data;
    } else {
      console.log(response.status + ' ' + response.data);
    }
  } catch {
    return undefined;
  }

  return undefined;
};
