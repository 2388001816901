import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FilterableSelect from 'src/components/FilterableSelect';
import {
  ListItem,
  NewListItem,
  QueryFilterCondition,
  QueryInclude,
  QuerySelectField,
  ReportDataQuery,
  SchemaTableInfo,
} from 'src/interfaces/interfaces';
import {
  allTablesSelector,
  relatedTablesSelector,
  tableFieldsSelector,
  CustomReportActions,
  loadedQuerySelector,
  queriesLoadingSavingSelector,
  isAddQuerySuccessSelector,
} from 'src/store/customReport/index';
import styled from 'styled-components';
import {
  ForwardRefFieldProps,
  ReportQueryFieldRow,
} from './ReportQueryFieldRow';
import {
  ForwardRefFilterProps,
  ReportQueryFilterRow,
} from './ReportQueryFilterRow';
import { FormGroupDiv } from 'src/styledComponents/index';
import { Loader } from 'src/components/Loader';
import { GlobalMessageBox } from 'src/components/MessageBox';

interface ReportQueryFormProps {
  queryId?: number;
  onClose: () => void;
}

const StyledDiv = styled.div({
  display: 'flex',
  width: '100%',
  maxWidth: 'calc(100% - 150px)',
});

const FormButton = styled.button({
  marginBottom: '30px',
});

const StyledModal = styled(Modal)({
  maxWidth: '50%',
  '& .modal-content': {
    minHeight: '400px',
  },
});

const emptyQuery: ReportDataQuery = {
  id: 0,
  queryName: 'New Query',
  rootTableName: '',
  rootTableAssemblyName: '',
  includes: [],
  selectFields: [],
  filters: [],
  reports: [],
  dbSetName: '',
  rootTableDisplayName: '',
  canEdit: false,
  users: [],
};

const StyledContainer = styled.div({
  padding: 0,
});

export const ReportQueryForm = ({ queryId, onClose }: ReportQueryFormProps) => {
  const [localQuery, setLocalQuery] = useState<ReportDataQuery>(emptyQuery);
  const [sendQuery, setSendQuery] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const dispatch = useDispatch();
  const baseRecords = useSelector(allTablesSelector);
  const relatedTables = useSelector(relatedTablesSelector);
  const tableFields = useSelector(tableFieldsSelector);
  const query = useSelector(loadedQuerySelector);
  const isQueryLoadingSaving = useSelector(queriesLoadingSavingSelector);
  const addQuerySuccess = useSelector(isAddQuerySuccessSelector);

  const titleRef = useRef<HTMLInputElement>(null);
  const fieldRefs = useRef<ForwardRefFieldProps[]>([]);
  const filterRefs = useRef<ForwardRefFilterProps[]>([]);

  const operators = [
    new ListItem('=', '='),
    new ListItem('!=', '!='),
    new ListItem('>', '>'),
    new ListItem('<', '<'),
    new ListItem('>=', '>='),
    new ListItem('<=', '<='),
  ];

  const availableIncludes = useMemo(() => {
    const related = relatedTables[localQuery.rootTableAssemblyName];
    if (related) {
      return related;
    } else {
      return [];
    }
  }, [localQuery.rootTableAssemblyName, relatedTables]);

  const availableIncludeList = useMemo(() => {
    return availableIncludes.map(
      (x) => new ListItem(x.assemblyQualifiedName, x.displayName)
    );
  }, [availableIncludes, localQuery]);

  const availableTables = useMemo(() => {
    const baseTable: SchemaTableInfo = {
      assemblyQualifiedName: localQuery.rootTableAssemblyName,
      navigationPropName: '',
      isCollection: false,
      tableName: localQuery.rootTableName,
      displayName: localQuery.rootTableDisplayName,
    };

    return [baseTable, ...availableIncludes];
  }, [localQuery.rootTableAssemblyName, availableIncludes, query]);

  const availableTableFields = useCallback(
    (field: QuerySelectField) => {
      const table = availableTables.find(
        (x) => x.tableName == field.sourceTableName
      );
      if (table) {
        const properties = tableFields[table.assemblyQualifiedName].filter(
          (x) =>
            field.propertyName === x.propertyName ||
            !localQuery.selectFields.some(
              (y) => x.propertyName == y.propertyName
            )
        );
        const availableFields = { ...tableFields };

        availableFields[table.assemblyQualifiedName] = properties;
        return availableFields;
      } else {
        return tableFields;
      }
    },
    [localQuery.selectFields, tableFields]
  );

  const selectedTableList = useMemo(() => {
    const list: NewListItem[] = [];
    if (localQuery.rootTableAssemblyName) {
      list.push(
        new NewListItem(
          localQuery.rootTableAssemblyName,
          localQuery.rootTableDisplayName,
          {
            tableName: localQuery.rootTableName,
            assemblyQualifiedName: localQuery.rootTableAssemblyName,
          }
        )
      );

      if (localQuery.includes.length > 0) {
        localQuery.includes.map((include) => {
          const item = availableIncludes.find(
            (x) => x.assemblyQualifiedName == include.assmeblyQualifiedName
          );

          if (item) {
            list.push(
              new NewListItem(item.assemblyQualifiedName, item.displayName, {
                tableName: item.tableName,
                assemblyQualifiedName: item.assemblyQualifiedName,
                isCollection: item.isCollection,
              })
            );
          }
        });
      }
    }
    return list;
  }, [localQuery, availableIncludes]);

  const selectedTableFilterList = useMemo(() => {
    const list = selectedTableList.filter(
      (x) => (x.item as SchemaTableInfo).isCollection !== true
    );
    return list;
  }, [selectedTableList]);

  const baseRecordList = useMemo(() => {
    return baseRecords.map(
      (x) => new ListItem(x.assemblyQualifiedName, x.displayName)
    );
  }, [baseRecords]);

  const updateBaseRecord = (qualifiedName: string) => {
    const table = baseRecords.find(
      (x) => x.assemblyQualifiedName == qualifiedName
    );
    if (table) {
      setLocalQuery((prevState) => ({
        ...prevState,
        rootTableAssemblyName: table.assemblyQualifiedName,
        rootTableName: table.tableName,
        rootTableDisplayName: table.displayName,
        includes: [],
      }));

      const related = relatedTables[table.assemblyQualifiedName];
      if (!related) {
        dispatch(
          CustomReportActions.getRelatedTables(table.assemblyQualifiedName)
        );
      }

      const fields = tableFields[table.assemblyQualifiedName];
      if (!fields) {
        dispatch(CustomReportActions.getFields(table.assemblyQualifiedName));
      }
    }
  };

  const addNewInclude = () => {
    const newInclude: QueryInclude = {
      id: 0,
      dataQueryId: 0,
      navigationPropName: '',
      isCollection: false,
      tableClassName: '',
      assmeblyQualifiedName: '',
      displayName: '',
      dbSetName: '',
    };
    setLocalQuery((prevState) => ({
      ...prevState,
      includes: [...prevState.includes, newInclude],
    }));
  };

  const addNewField = () => {
    const newField: QuerySelectField = {
      sourceTableName: '',
      propertyName: '',
      query: null,
    };
    setLocalQuery((prevState) => ({
      ...prevState,
      selectFields: [...prevState.selectFields, newField],
    }));
  };

  const addNewFilterCondition = () => {
    const newCondition: QueryFilterCondition = {
      sourceTableName: '',
      sourceQualifiedAssemblyName: '',
      propertyName: '',
      operator: '',
      value: '',
      query: null,
    };
    setLocalQuery((prevState) => ({
      ...prevState,
      filters: [...prevState.filters, newCondition],
    }));
  };

  const updateIncludes = useCallback(
    (qualifiedTableName: string, index: number) => {
      const item = availableIncludes.find(
        (x) => x.assemblyQualifiedName == qualifiedTableName
      );

      if (item) {
        const array = localQuery.includes.slice();

        const newInclude: QueryInclude = {
          id: 0,
          dataQueryId: 0,
          navigationPropName: item.navigationPropName,
          isCollection: item.isCollection,
          tableClassName: item.tableName,
          assmeblyQualifiedName: item.assemblyQualifiedName,
          displayName: item.displayName,
          query: undefined,
          dbSetName: '',
        };

        array.splice(index, 1, newInclude);

        setLocalQuery((prevState) => ({
          ...prevState,
          includes: [...array],
        }));
      }

      const fields = tableFields[qualifiedTableName];
      if (!fields) {
        dispatch(CustomReportActions.getFields(qualifiedTableName));
      }
    },
    [availableIncludes, localQuery]
  );

  const removeInclude = useCallback(
    (include: QueryInclude) => {
      if (include) {
        const includes = localQuery.includes.filter((x) => x != include);
        setLocalQuery((prevState) => ({
          ...prevState,
          includes: [...includes],
        }));
      }
    },
    [localQuery]
  );

  const removeSelectField = useCallback(
    (field: QuerySelectField, index: number) => {
      if (field) {
        fieldRefs.current.splice(index, 1);
        const fields = localQuery.selectFields.filter((x) => x != field);
        setLocalQuery((prevState) => ({
          ...prevState,
          selectFields: [...fields],
        }));
      }
    },
    [localQuery]
  );

  const updateSelectField = useCallback(
    (field: QuerySelectField, index: number) => {
      const array = localQuery.selectFields.slice();

      const newField: QuerySelectField = {
        sourceTableName: field.sourceTableName,
        propertyName: field.propertyName,
        query: null,
      };

      array.splice(index, 1, newField);

      setLocalQuery((prevState) => ({
        ...prevState,
        selectFields: [...array],
      }));
    },
    [localQuery]
  );

  const removeFilterCondition = useCallback(
    (condition: QueryFilterCondition, index: number) => {
      if (condition) {
        filterRefs.current.splice(index, 1);
        const conditions = localQuery.filters.filter((x) => x != condition);
        setLocalQuery((prevState) => ({
          ...prevState,
          filters: [...conditions],
        }));
      }
    },
    [localQuery]
  );

  const updateFilterCondition = useCallback(
    (condition: QueryFilterCondition, index: number) => {
      const array = localQuery.filters.slice();

      const newCondition: QueryFilterCondition = {
        sourceTableName: condition.sourceTableName,
        sourceQualifiedAssemblyName: condition.sourceQualifiedAssemblyName,
        propertyName: condition.propertyName,
        operator: condition.operator,
        value: condition.value,
        query: null,
      };

      array.splice(index, 1, newCondition);

      setLocalQuery((prevState) => ({
        ...prevState,
        filters: [...array],
      }));
    },
    [localQuery]
  );

  const saveQuery = useCallback(async () => {
    if (
      localQuery.rootTableName == '' ||
      localQuery.rootTableAssemblyName == ''
    ) {
      dispatch({
        type: 'UPDATE_MESSAGE_ACTION',
        message: 'Error: Please verify all fields are correctly entered.',
      });
      return;
    }

    if (fieldRefs.current && fieldRefs.current.length > 0) {
      if (fieldRefs.current.some((x) => x.isInvalid)) {
        dispatch({
          type: 'UPDATE_MESSAGE_ACTION',
          message: 'Error: Please verify all fields are correctly entered.',
        });
        return;
      }
    }
    if (filterRefs.current && filterRefs.current.length > 0) {
      if (filterRefs.current.some((x) => x.isInvalid)) {
        dispatch({
          type: 'UPDATE_MESSAGE_ACTION',
          message: 'Error: Please verify all fields are correctly entered.',
        });
        return;
      }
    }

    setSendQuery(true);
  }, [localQuery]);

  useEffect(() => {
    if (!query) {
      if (queryId && queryId > 0) {
        dispatch(CustomReportActions.getQuery(queryId));
      } else if ((!queryId || queryId == 0) && baseRecords.length < 1) {
        dispatch(CustomReportActions.getAllTables());
      }
    } else if (query) {
      if (queryId && queryId > 0 && queryId !== query.id) {
        dispatch(CustomReportActions.getQuery(queryId));
      } else if (queryId == 0) {
        setLocalQuery(emptyQuery);
        if (baseRecords.length < 1) {
          dispatch(CustomReportActions.getAllTables());
        }
      } else {
        setLocalQuery(query);
      }
    }
  }, [queryId, baseRecords, query]);

  useEffect(() => {
    if (sendQuery) {
      if (query && localQuery.id > 0) {
        dispatch(CustomReportActions.updateQuery(localQuery));
      } else {
        dispatch(CustomReportActions.addQuery(localQuery));
      }
      setSendQuery(false);
    }
  }, [sendQuery, localQuery]);

  useEffect(() => {
    if (addQuerySuccess) {
      onClose();
      dispatch(CustomReportActions.setIsSuccessfulAddQuery(false));
    }
  }, [addQuerySuccess]);

  return (
    <div>
      <GlobalMessageBox />
      <Loader loading={isQueryLoadingSaving}></Loader>
      <StyledModal isOpen={true}>
        <ModalHeader className="border-bottom-0">
          {isEditingTitle ? (
            <div style={{ display: 'flex' }}>
              <input
                style={{ fontSize: '20px', height: 'unset', width: '350px' }}
                ref={titleRef}
                className="form-control"
                defaultValue={localQuery.queryName}
              />
              <button
                className="btn btn-sm btn-blue"
                onClick={(e) => {
                  setLocalQuery((prevState) => ({
                    ...prevState,
                    queryName: titleRef.current?.value ?? 'New Query',
                  }));
                  setIsEditingTitle(false);
                }}
                style={{
                  marginRight: '8px',
                  marginLeft: '8px',
                }}
              >
                Save
              </button>
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={() => setIsEditingTitle(false)}
              >
                Cancel
              </button>
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <h5>{localQuery.queryName}</h5>{' '}
              <span
                className="fas fa-edit"
                onClick={() => setIsEditingTitle(true)}
              ></span>
            </div>
          )}
        </ModalHeader>
        <ModalBody
          style={{ minHeight: '20vh', maxHeight: '65vh', overflowY: 'auto' }}
          className="custom-scrollbar"
        >
          <h6>Tables</h6>
          <hr />
          <FormGroupDiv className="form-group">
            <div className="inline-label">Base Record</div>
            <div>
              {localQuery.id > 0 ? (
                <input
                  id={'f-select-base'}
                  disabled={true}
                  className={'form-control'}
                  value={localQuery.rootTableDisplayName}
                ></input>
              ) : (
                <FilterableSelect
                  id={'f-select-base'}
                  items={baseRecordList}
                  onChange={(x) => updateBaseRecord(x)}
                  required={true}
                  defaultVal={localQuery.rootTableAssemblyName}
                ></FilterableSelect>
              )}
            </div>
          </FormGroupDiv>
          {localQuery.includes.map((include, idx) => {
            return (
              <FormGroupDiv key={'includes_' + idx} className="form-group">
                <div className="inline-label">Include</div>
                <StyledDiv>
                  <FilterableSelect
                    id={'f-select-include' + idx}
                    items={availableIncludeList.filter(
                      (u) =>
                        u.id === include.assmeblyQualifiedName ||
                        !localQuery.includes.some(
                          (y) => y.assmeblyQualifiedName === u.id
                        )
                    )}
                    onChange={(x) => updateIncludes(x, idx)}
                    defaultVal={include.assmeblyQualifiedName}
                  ></FilterableSelect>
                  <span
                    style={{ borderRadius: '50%' }}
                    className="btn btn-sm btn-round fas fa-trash btn-background-hover"
                    title="Delete this field"
                    onClick={() => removeInclude(include)}
                  ></span>
                </StyledDiv>
              </FormGroupDiv>
            );
          })}

          {availableIncludes.length > 0 &&
            localQuery.includes.length < availableIncludes.length && (
              <FormButton
                onClick={() => addNewInclude()}
                className="btn btn-sm btn-blue"
              >
                Add Record
              </FormButton>
            )}
          {availableTables.length > 0 && localQuery.rootTableAssemblyName && (
            <>
              <h6>Fields</h6>
              <hr />
              <StyledContainer className="container">
                <div className="row">
                  <div className="inline-label col">Table</div>
                  <div className="inline-label col">Field Name</div>
                  <div style={{ marginLeft: '60px' }}></div>
                </div>
                {localQuery.selectFields.map((field, idx) => {
                  return (
                    <ReportQueryFieldRow
                      key={'field-row_' + idx}
                      ref={(el) => {
                        if (el && fieldRefs.current) {
                          fieldRefs.current[idx] = el;
                        }
                      }}
                      tables={selectedTableList}
                      fields={availableTableFields(field)}
                      field={field}
                      onUpdate={(x) => updateSelectField(x, idx)}
                      onRemove={() => removeSelectField(field, idx)}
                    ></ReportQueryFieldRow>
                  );
                })}
              </StyledContainer>
              <FormButton
                onClick={() => addNewField()}
                className="btn btn-x-sm btn-blue"
              >
                Add Field
              </FormButton>

              <h6>Filter Conditions</h6>
              <hr />
              <StyledContainer className="container">
                <div className="row">
                  <div className="inline-label col">Table</div>
                  <div className="inline-label col">Property</div>
                  <div className="inline-label col">Operator</div>
                  <div className="inline-label col">Value</div>
                  <div style={{ marginLeft: '30px' }}></div>
                </div>
                {localQuery.filters.map((condition, idx) => {
                  return (
                    <ReportQueryFilterRow
                      key={'filter-row_' + idx}
                      ref={(el) => {
                        if (el && filterRefs.current) {
                          filterRefs.current[idx] = el;
                        }
                      }}
                      tables={selectedTableFilterList}
                      fields={tableFields}
                      operators={operators}
                      condition={condition}
                      onUpdate={(x) => updateFilterCondition(x, idx)}
                      onRemove={() => removeFilterCondition(condition, idx)}
                    ></ReportQueryFilterRow>
                  );
                })}
              </StyledContainer>
              <FormButton
                onClick={() => addNewFilterCondition()}
                className="btn btn-x-sm btn-blue"
              >
                Add Filter
              </FormButton>
            </>
          )}
          <div></div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-sm"
            color="success"
            onClick={() => saveQuery()}
          >
            Save
          </Button>
          <Button
            className="btn-sm"
            color="outline-secondary"
            onClick={() => onClose()}
          >
            Close
          </Button>
        </ModalFooter>
      </StyledModal>
    </div>
  );
};
