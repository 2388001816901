import React from 'react';
import { FileExplorerItem } from 'src/interfaces/interfaces';
import Modal from '../Modal';

interface FileExplorerDeleteMulitItemModalProps {
  items: FileExplorerItem[];
  onDeleteItems: () => void;
  onToggleDeleteModal: () => void;
}

export const FileExplorerDeleteMulitItemModal = ({
  items,
  onDeleteItems,
  onToggleDeleteModal,
}: FileExplorerDeleteMulitItemModalProps) => {
  return (
    <Modal>
      <div className="modal-header">
        <h4>Delete Item</h4>
      </div>
      <div className="modal-body">
        <div style={{ fontSize: '14px' }}>
          Are you sure you want to delete these items?
        </div>
        <ul>
          {items.map((item) => (
            <li key={item.name}>{item.name}</li>
          ))}
        </ul>
        {/*<p><b>Note:</b> This will delete all sub-folders under {folderName}</p>*/}
      </div>
      <div className="modal-footer">
        <button className="btn btn-sm btn-danger" onClick={onDeleteItems}>
          Delete
        </button>
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={onToggleDeleteModal}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};
