import * as React from 'react';
import { RouteComponentProps, useParams, useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import { ApplicationState } from '../store';
import * as UserStore from '../store/user';
import { Tabs, TabLink, TabContent } from '../tabs';
import { VendorInfoForm } from './VendorInfoForm';
import { VendorVm } from '../interfaces/interfaces';
import { VendorProjectGrid } from './VendorProjectGrid';
import { VendorUserGrid } from './VendorUserGrid';
import { VendorComplianceTab } from './VendorCompliance';
import { FileExplorer } from '../components/FileExplorer';
import { FileExplorerType } from '../interfaces/enums';
import { hasRole, isUserById, KnownRoles } from '../auth/auth';
import { VendorChecklistGrid } from './VendorChecklistGrid';
import { RectangleLoader } from '../loaders/RectangleLoader';
import { ConnectedFileExplorer } from 'src/components/ConnectedFileExplorer';
import { BackLink } from '../components/BackLink';
import { VendorRegistrationReview } from './VendorRegistrationReview';
import { VendorFedCertsGrid } from './VendorFedCertsGrid';
import { useDispatch } from 'react-redux';
import { VendorsActions } from 'src/store/vendors';
import { addFedCert, deleteFedCert } from 'src/services/vendors';

export const VendorDetail = () => {
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const dispatch = useDispatch();
  console.log(params);

  const [state, setState] = React.useState({
    vendorId: parseInt(params.id),
    defaultTab: location.hash.substr(1),
    loading: true,
    vendorInfo: {} as VendorVm,
  });

  const userStore = useSelector((s: ApplicationState) => s.user);

  React.useEffect(() => {
    _getVendorInfo();
    dispatch(VendorsActions.getVendorFedCertTypes());
  }, []);

  const _getVendorInfo = () => {
    setState({ ...state, loading: true });
    fetch(`api/Vendor/Detail?id=${state.vendorId}`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) setState({ ...state, vendorInfo: data, loading: false });
      });
  };

  const onAddCert = React.useCallback(
    async (type: number, file: File) => {
      const response = await addFedCert(state.vendorInfo.id ?? 0, type, file);
      if (response) {
        _getVendorInfo();
      }
    },
    [state]
  );

  const onDeleteCert = React.useCallback(
    async (id: number) => {
      const response = await deleteFedCert(id);
      if (response) {
        _getVendorInfo();
      }
    },
    [state]
  );

  const { vendorId, vendorInfo, loading } = state;
  const { user } = userStore;
  return (
    <div className="vendor-detail">
      <BackLink link={`/subs`} />
      <div className="pageHeader">
        {loading ? (
          <RectangleLoader height="40px" width="300px" />
        ) : (
          vendorInfo.name
        )}
      </div>
      <Tabs
        name="vendor-tabs"
        onChange={(t: string) => {}}
        recallTab={state.defaultTab}
        renderActiveTabContentOnly={true}
      >
        <div className="tab-links">
          <TabLink to="info" component="span">
            <span>Info</span>
          </TabLink>
          <TabLink to="users" component="span">
            <span>Users</span>
          </TabLink>
          <TabLink to="projects" component="span">
            <span>Projects</span>
          </TabLink>
          <TabLink to="compliance" component="span">
            <span>Compliance</span>
          </TabLink>
          <TabLink to="checklists" component="span">
            <span>Checklists</span>
          </TabLink>
          <TabLink to="explorer" component="span">
            <span>File Explorer</span>
          </TabLink>
          <TabLink to="certs" component="span">
            <span>Federation Certs</span>
          </TabLink>
          <TabLink to="review" component="span">
            <span>Registration Review</span>
          </TabLink>
        </div>
        <div className="content">
          <TabContent for="info">
            {/* We can force the VendorInfoForm to wait for the info prop instead of making its own call by NOT passing the id prop */}
            <VendorInfoForm info={vendorInfo} key={vendorId} />
          </TabContent>
          <TabContent for="users">
            <VendorUserGrid id={state.vendorId} />
          </TabContent>
          <TabContent for="projects">
            <VendorProjectGrid vendor={vendorInfo} />
          </TabContent>
          <TabContent for="compliance">
            <VendorComplianceTab vendorId={state.vendorId} />
          </TabContent>
          <TabContent for="explorer">
            <ConnectedFileExplorer
              key={state.vendorId}
              startingDir={`Vendors/${state.vendorId}`}
              breadCrumbStartLevel={2}
              canSubscribeUsers={hasRole(user, [
                KnownRoles.Admin,
                KnownRoles.CoreSuperUser,
              ])}
              params={{ id: state.vendorId }}
              type={FileExplorerType.Vendor}
              droppableId={'vendor-detail-droppable'}
              draggableId={'vendor-detail-draggable'}
            />
          </TabContent>
          <TabContent for="checklists">
            <VendorChecklistGrid vendorId={state.vendorId} />
          </TabContent>
          <TabContent for="certs">
            <VendorFedCertsGrid
              loading={loading}
              fedCerts={state.vendorInfo.federationCertifications ?? []}
              onAddCert={onAddCert}
              onDeleteCert={(id) => onDeleteCert(id)}
            />
          </TabContent>
          <TabContent for="review">
            <VendorRegistrationReview
              partnerId={state.vendorInfo.vettedPartnerId}
            />
          </TabContent>
        </div>
      </Tabs>
    </div>
  );
};

////@ts-ignore
//export default connect(
//  (state: ApplicationState) => state.user,
//  null
//  // @ts-ignore
//)(VendorDetail) as typeof VendorDetail
