import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Layout } from './layout/Layout';

import './custom.css';
import { WhatsNew } from './pages/WhatsNew';
import LoginPage from './components/auth-pages/LoginPage';
import ForgotPassword from './components/auth-pages/ForgotPassword';

import * as SWStore from './store/swStore';
import * as UserStore from './store/user';
import { ApplicationState } from './store/index';
import ResetPassword from './components/auth-pages/ResetPassword';
import EmailConfirmed from './components/auth-pages/EmailConfirmed';
import DashboardHome from './dashboard/DashboardHome';
import Projects from './projects/Projects';
import { ProjectHome } from './projects/ProjectHome';
import { RedLines } from './pages/Redlines';
import { ConfigHome } from './config/Config';
import AdminHome from './admin/AdminHome';
import GlobalFileExplorer from './pages/GlobalFileExplorer';
import { AuthorizationComponent, MyAuthElement } from './auth/Authorization';
import { KnownRoles } from './auth/auth';
import Snapshot from './pages/Snapshot';
import { DfrPage } from './dfr/DfrPage';
import { SubmittalPage } from './submittals/SubmittalPage';
import RFIPage from './rfi/RFIPage';
import SubmittalPackageDetail from './submittals/SubmittalPackageDetail';
import AccountManagementHome from './accountPrefs/AccountManagementHome';
import Alert from './components/Alert';
import { FileShare } from './pages/FileShare';
import { VendorsPage } from './vendors/Vendors';
import { VendorDetail } from './vendors/VendorDetail';
import { RFIReissueRequestDetail } from './rfi/RFIReissueRequestDetail';
import * as GlobalStore from './store/global';
import { TransmittalPage } from './transmittals/TransmittalPage';
import { MyNotifications } from './pages/MyNotifications';
import { ProcurementDetailPage } from './procurement/ProcurementDetailPage';
import { useInterval } from './helpers/customhooks';
import { ReportingHome } from './customReports/ReportingHome';
import { CustomReportPage } from './customReports/CustomReport/CustomReportPage';
import { IssueDetailPage } from './issues/IssueDetailPage';
import { AccountDeletion } from './components/auth-pages/AccountDeletion';

export const App = () => {
  const AdminComp = AuthorizationComponent([KnownRoles.Admin]);
  const SuperUserPmComp = AuthorizationComponent([
    KnownRoles.Admin,
    KnownRoles.CoreSuperUser,
    KnownRoles.CSGPM,
  ]);
  const SuperUserPmComponent = MyAuthElement([
    KnownRoles.Admin,
    KnownRoles.CoreSuperUser,
    KnownRoles.CSGPM,
  ]);
  const AllButVendorComp = AuthorizationComponent([
    KnownRoles.Admin,
    KnownRoles.ClientAdmin,
    KnownRoles.ClientPM,
    KnownRoles.CoreSuperUser,
    KnownRoles.CSGPM,
    KnownRoles.Owner,
    KnownRoles.SiteSuper,
  ]);
  const InternalUsersComp = AuthorizationComponent([
    KnownRoles.Admin,
    KnownRoles.CoreSuperUser,
    KnownRoles.CSGPM,
    KnownRoles.SiteSuper,
  ]);

  const userStore = useSelector((s: ApplicationState) => s.user);
  const swStore = useSelector((s: ApplicationState) => s.serviceWorker);
  const dispatch = useDispatch();

  useInterval(() => {
    dispatch(GlobalStore.actionCreators.getUsers());
  }, 3000);

  React.useEffect(() => {
    dispatch(UserStore.actionCreators.getUser());
    //dispatch(GlobalStore.actionCreators.getFavoriteProjects());
    //dispatch(GlobalStore.actionCreators.getRecentProjects());
  }, []);

  React.useEffect(() => {
    dispatch(UserStore.actionCreators.getUser());
    dispatch(GlobalStore.actionCreators.getFavoriteProjects());
    dispatch(GlobalStore.actionCreators.getRecentProjects());
  }, [userStore.user?.userId]);

  const updateServiceWorker = () => {
    const { registration } = swStore;
    if (registration) {
      const registrationWaiting = registration.waiting;

      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

        registrationWaiting.addEventListener('statechange', (e) => {
          // @ts-ignore
          if (e.target.state === 'activated') {
            window.location.reload();
          }
        });
      }
    }
  };

  if (!userStore.authChecked) return <div>Loading...</div>;

  if (userStore.user) {
    if (swStore.updated) {
      return (
        <Alert
          text="There is a new version of the application available"
          buttonText="Update"
          type="SW_UPDATE"
          onClick={updateServiceWorker}
        />
      );
    } else {
      //console.log('app.tsx rendering');
      return (
        <Layout>
          <Route exact path="/" component={DashboardHome} />
          <Route exact path="/dashboard" component={DashboardHome} />
          <Route
            exact
            path="/explorer"
            component={AllButVendorComp(GlobalFileExplorer)}
          />
          <Route
            exact
            path="/fileshare"
            component={SuperUserPmComp(FileShare)}
          />
          <Route path="/projects" component={Projects} />
          <Route path="/project/:id" component={ProjectHome} />
          <Route path="/redlines" component={AllButVendorComp(RedLines)} />
          <Route path="/snapshot" component={AllButVendorComp(Snapshot)} />
          <Route path="/config" component={SuperUserPmComp(ConfigHome)} />
          <Route path="/admin" component={AdminComp(AdminHome)} />
          <Route path="/account-settings" component={AccountManagementHome} />
          <Route exact path="/account-deletion" component={AccountDeletion} />
          <Route path="/dfr/:id" component={DfrPage} />
          <Route exact path="/my-notifications" component={MyNotifications} />
          <Route
            path="/procurement/:projectId/:itemId"
            component={ProcurementDetailPage}
          />
          <Route
            path="/reporting"
            component={InternalUsersComp(ReportingHome)}
          />
          <Route
            path="/customReport/:id"
            component={InternalUsersComp(CustomReportPage)}
          />
          <Route path="/rfi/:id" component={RFIPage} />
          <Route
            path="/reissuerequest/:id"
            component={RFIReissueRequestDetail}
          />
          <Route path="/submittal/:id" component={SubmittalPage} />
          <Route path="/package/:id" component={SubmittalPackageDetail} />
          <Route
            path="/transmittal/:projectId/:transmittalId"
            component={TransmittalPage}
          />
          <Route path="/issue/:projectId/:id" component={IssueDetailPage} />
          <Route path="/subs" component={InternalUsersComp(VendorsPage)} />
          <Route path="/sub/:id" component={InternalUsersComp(VendorDetail)} />
          <Route exact path="/whatsnew" component={WhatsNew} />
          <Route path="/my-work">
            <DashboardHome hideTabs selectedTab="mywork" />
          </Route>
          <Route path="/my-milestones">
            <DashboardHome hideTabs selectedTab="milestones" />
          </Route>
          <Route path="/my-checklists">
            <DashboardHome hideTabs selectedTab="checklists" />
          </Route>
          <Route path="/my-tasks">
            <DashboardHome hideTabs selectedTab="tasks" />
          </Route>
          <Route path="/activity">
            <DashboardHome hideTabs selectedTab="activity" />
          </Route>
          <Route path="/my-graphs">
            <DashboardHome hideTabs selectedTab="graphs" />
          </Route>
          <Route path="/projectlist" component={Projects} />
          <Route path="/config-client">
            <SuperUserPmComponent>
              <ConfigHome hideTabs selectedTab="client" />
            </SuperUserPmComponent>
          </Route>
          <Route path="/config-custom-field">
            <SuperUserPmComponent>
              <ConfigHome hideTabs selectedTab="customFields" />
            </SuperUserPmComponent>
          </Route>
          <Route path="/config-checklist">
            <SuperUserPmComponent>
              <ConfigHome hideTabs selectedTab="checklists" />
            </SuperUserPmComponent>
          </Route>
          <Route path="/config-kpi">
            <SuperUserPmComponent>
              <ConfigHome hideTabs selectedTab="kpis" />
            </SuperUserPmComponent>
          </Route>
          <Route path="/config-quality-report">
            <SuperUserPmComponent>
              <ConfigHome hideTabs selectedTab="qualityReports" />
            </SuperUserPmComponent>
          </Route>
          <Route path="/config-punchlist">
            <SuperUserPmComponent>
              <ConfigHome hideTabs selectedTab="punchlists" />
            </SuperUserPmComponent>
          </Route>
          <Route path="/config-global">
            <SuperUserPmComponent>
              <ConfigHome hideTabs selectedTab="global" />
            </SuperUserPmComponent>
          </Route>
          <Route path="/config-region">
            <SuperUserPmComponent>
              <ConfigHome hideTabs selectedTab="regions" />
            </SuperUserPmComponent>
          </Route>
          <Route path="/config-import">
            <SuperUserPmComponent>
              <ConfigHome hideTabs selectedTab="imports" />
            </SuperUserPmComponent>
          </Route>
          <Route path="/config-tracker">
            <SuperUserPmComponent>
              <ConfigHome hideTabs selectedTab="pipeline" />
            </SuperUserPmComponent>
          </Route>
        </Layout>
      );
    }
    //else {
    //    return (
    //        <div style={{ color: 'white', backgroundColor: '#002e6d', padding: '300px', textAlign: 'center', height: '100vh' }}>
    //            <h1>Application Offline</h1>
    //            <hr style={{ borderWidth: '2px', borderColor: 'white' }} />
    //            <h3>The application is currently inaccessible for maintenance. Please contact the application administrator with any questions</h3>
    //        </div>
    //    )
    //}
  } else {
    return (
      <div>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/account-confirmed" component={EmailConfirmed} />
          <Route exact path="/account-deletion" component={AccountDeletion} />
          <Route path="/" component={LoginPage} />
        </Switch>
      </div>
    );
  }
};

//class App extends React.Component<Props, {}> {

//    AdminComp = AuthorizationComponent([KnownRoles.Admin])
//    SuperUserPmComp = AuthorizationComponent([KnownRoles.Admin, KnownRoles.CoreSuperUser, KnownRoles.CSGPM])
//    AllButVendorComp = AuthorizationComponent([KnownRoles.Admin, KnownRoles.ClientAdmin, KnownRoles.ClientPM, KnownRoles.CoreSuperUser,
//        KnownRoles.CSGPM, KnownRoles.Owner, KnownRoles.SiteSuper])

//    componentDidMount() {
//        this.props.getUser();
//        this.props.getUsers()
//    }

//    componentDidUpdate() {
//        if (!this.props.u.authChecked) this.props.getUser()
//    }

//    updateServiceWorker = () => {
//        const { registration } = this.props.sw;
//        if (registration) {
//            const registrationWaiting = registration.waiting;

//            if (registrationWaiting) {
//                registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

//                registrationWaiting.addEventListener('statechange', (e) => {
//                    // @ts-ignore
//                    if (e.target.state === 'activated') {
//                        window.location.reload();
//                    }
//                });
//            }
//        }

//    }

//    render() {
//        const { u, sw } = this.props,
//            { authChecked, user } = u,
//            { registration, initialized, updated } = sw;

//        if (!authChecked) return <div>Loading...</div>

//        if (user) {
//            if (updated) {
//                return <Alert
//                    text='There is a new version of the application available'
//                    buttonText='Update'
//                    type='SW_UPDATE'
//                    onClick={this.updateServiceWorker}
//                />
//            }
//            else {
//                return (
//                    <Layout>
//                        <Route exact path='/' component={DashboardHome} />
//                        <Route exact path='/dashboard' component={DashboardHome} />
//                        <Route exact path='/explorer' component={this.AllButVendorComp(GlobalFileExplorer)} />
//                        <Route exact path='/fileshare' component={this.SuperUserPmComp(FileShare)} />
//                        <Route path='/projects' component={Projects} />
//                        <Route path='/project/:id' component={ProjectDetail} />
//                        <Route path='/redlines' component={this.AllButVendorComp(Redlines)} />
//                        <Route path='/snapshot' component={this.AllButVendorComp(Snapshot)} />
//                        <Route path='/config' component={this.SuperUserPmComp(Config)} />
//                        <Route path='/admin' component={this.AdminComp(AdminHome)} />
//                        <Route path='/accPref' component={AccountManagementHome} />
//                        <Route path='/dfr/:id' component={DfrMain} />
//                        <Route path='/rfi/:id' component={RFIDetail} />
//                        <Route path='/reissuerequest/:id' component={RFIReissueRequestDetail} />
//                        <Route path='/submittal/:id' component={SubmittalDetail} />
//                        <Route path='/package/:id' component={SubmittalPackageDetail} />
//                        <Route path='/vendors' component={this.SuperUserPmComp(VendorsPage)} />
//                        <Route path='/vendor/:id' component={this.SuperUserPmComp(VendorDetail)} />
//                        <Route exact path='/whatsnew' component={WhatsNew} />
//                    </Layout>
//                );
//            }
//        }
//        else {
//            return (
//                <div>
//                    <Switch>
//                        <Route exact path='/login' component={LoginPage} />
//                        <Route exact path='/forgot-password' component={ForgotPassword} />
//                        <Route exact path='/reset-password' component={ResetPassword} />
//                        <Route exact path='/account-confirmed' component={EmailConfirmed} />
//                        <Route path='/' component={LoginPage} />
//                    </Switch>
//                </div>
//            )
//        }
//    }
//}

//// @ts-ignore
////export default connect < { u: UserStore.Store, sw: SWStore.Store }, typeof UserStore.actionCreators, {}>(
//export default connect(
//    (state: ApplicationState) => {
//        return { u: state.user, g: state.global, sw: state.serviceWorker }
//    },
//    { ...UserStore.actionCreators, ...GlobalStore.actionCreators }

//    //@ts-ignore
//)(App)
