import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '..';
import { VendorsState } from './vendors.reducer';

const vendorsState = (state: ApplicationState): VendorsState => state.vendors;

export const vendorsAndSubsSelector = createSelector(
  vendorsState,
  (state) => state.vendorsAndSubs
);

export const fedCertTypesSelector = createSelector(
  vendorsState,
  (state) => state.fedCertTypes
);
