import * as React from 'react';
import {
  CustomizableGrid,
  MetaFieldInfo,
} from '../components/CustomizableGrid';
import { CSharpTypeCode, MetaObjectType } from '../interfaces/enums';
import {
  RegistrationProgress,
  RegistrationStatus,
  UnvettedPartner,
  VendorVm,
} from '../interfaces/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as globalActions } from '../store/global';
import {
  KeyVaultActions,
  partnerRegKeySelector,
} from '../store/keyvault/index';
import { UnvettedPartnerDetail } from './UnvettedPartnerDetail';
import { PartnerRegistrationUrl } from './PartnerRegistrationConstants';

export const UnvettedPartnerList = () => {
  const [partners, setPartners] = React.useState([] as UnvettedPartner[]);
  const [selectedPartnerId, setSelectedPartnerId] = React.useState(
    null as number | null
  );
  const apiKey = useSelector(partnerRegKeySelector);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(KeyVaultActions.getPartnerRegistrationKey());
  }, []);

  React.useEffect(() => {
    if (apiKey) {
      // @ts-ignore
      _getPartners(apiKey);
    }
  }, [apiKey]);

  const _getPartners = (key: string) => {
    fetch(`${PartnerRegistrationUrl}/api/subform/getallexternal`, {
      //method: 'GET',
      headers: {
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setPartners(data);
        } else {
          dispatch(globalActions.updateMessage(data.message));
        }
      });
  };

  const cols: MetaFieldInfo[] = [
    {
      displayName: 'Name',
      propName: 'vendorName',
      jsType: 'string',
      propType: CSharpTypeCode.String,
      columnWidth: 4,
    },
    {
      displayName: 'Trades',
      propName: 'trades.tradeName',
      jsType: 'nested',
      propType: CSharpTypeCode.Object,
      columnWidth: 4,
    },
    {
      displayName: 'Regions',
      propName: 'serviceAreas',
      jsType: 'array',
      propType: CSharpTypeCode.Object,
      columnWidth: 3,
    },
    {
      displayName: 'Labor Type',
      propName: 'union',
      jsType: 'string',
      propType: CSharpTypeCode.String,
      columnWidth: 2,
    },
    {
      displayName: 'Vendor Type',
      propName: 'vendorType',
      jsType: 'string',
      propType: CSharpTypeCode.String,
      columnWidth: 2,
    },
    {
      displayName: 'Status',
      propName: 'status',
      jsType: 'number',
      propType: CSharpTypeCode.Int32,
      columnWidth: 2,
    },
    {
      displayName: 'Progress',
      propName: 'progress',
      jsType: 'number',
      propType: CSharpTypeCode.Int32,
      columnWidth: 2,
    },
  ];

  const _lineMap = (partner: UnvettedPartner) => {
    const colWidth = Math.floor(20 / cols.length);
    return (
      <div
        onClick={() => setSelectedPartnerId(partner.id)}
        className="col-sm-12 sv-grid-line"
        key={partner.id}
      >
        {/*{cols.map(c => {*/}
        {/*    <div className={`my-col-${colWidth}`}>{vendor[c.propName]}</div>*/}
        {/*})}*/}
        <div className={`my-col-${4} sortable truncate`}>
          {partner.vendorName}
        </div>
        <div className={`my-col-${4} sortable truncate`}>
          {partner.trades
            .map((x) => x.tradeName)
            .join(', ')
            .substring(0, 75)}
        </div>
        <div className={`my-col-${3} sortable truncate`}>
          {partner.serviceAreas.join(', ').substring(0, 75)}
        </div>
        <div className="my-col-2 sortable truncate">{partner.union}</div>
        <div className="my-col-2 sortable truncate">{partner.vendorType}</div>
        <div className="my-col-2 sortable truncate">
          {partner.status == RegistrationStatus.Active ? 'Active' : 'Inactive'}
        </div>
        <div className="my-col-2 sortable truncate">
          {PartnerProgressText(partner.progress)}
        </div>
      </div>
    );
  };

  const filteredPartners = React.useMemo(() => {
    return partners.filter((p) => p.conversionDate == null && p.status !== RegistrationStatus.Rejected);
  }, [partners]);

  return (
    <>
      {selectedPartnerId && (
        <UnvettedPartnerDetail
          partnerId={selectedPartnerId}
          close={() => setSelectedPartnerId(null)}
          removeFromList={(id) => { setPartners(partners.filter(p => p.id !== id)) }}
        />
      )}
      <CustomizableGrid
        type={MetaObjectType.Vendor}
        columns={cols}
        items={filteredPartners}
        mapCallback={_lineMap}
        className="vendor-grid"
      />
    </>
  );
};

export const PartnerProgressText = (status: RegistrationProgress) => {
  switch (status) {
    case RegistrationProgress.PhaseOneSubmitted:
      return 'Phase One';
    case RegistrationProgress.PhaseTwoSent:
      return 'Forms Sent';
    case RegistrationProgress.PhaseTwoSubmitted:
      return 'Forms Submitted';
    case RegistrationProgress.AllFormsReviewed:
      return 'Forms Reviewed';
  }
};
