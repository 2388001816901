import React, { useCallback, useEffect, useState } from 'react';
import { ExplorerItemType, FileExplorerType } from '../../interfaces/enums';
import { FileExplorerItem } from '../../interfaces/interfaces';
import { FileDownloader } from '../FileDownloader';
import { FolderPermissionLevel } from '../FolderPermissionsModal';
import { KnownRoles } from 'src/auth/auth';
import { MyAuthElement } from 'src/auth/Authorization';
import { Draggable } from '@hello-pangea/dnd';
import { useContextMenu } from 'react-contexify';
import { FileExplorerMenu } from './FileExplorerMenu';
import styled from 'styled-components';
import { use } from 'echarts';

interface FileExplorerItemsProps {
  items: FileExplorerItem[];
  locked: boolean;
  searchView: boolean;
  hoveredName: string | undefined;
  addToFolderName: string | undefined;
  editFolderName: string | undefined;
  subPath: string | undefined;
  startingDir: string;
  onFileHover: (path?: string, name?: string) => void;
  onFolderHover: (name?: string) => void;
  onToggleCarousel: (idx?: number) => void;
  onOpenFileTab: (path: string) => void;
  onSearchItems: (subPathText?: string, searchText?: string) => void;
  onEditFolder: (e: React.MouseEvent<HTMLElement>) => void;
  onAddFolder: () => void;
  onToggleEdit: (e: React.MouseEvent<HTMLElement>, name?: string) => void;
  onSubscribe: (e, path: string) => void;
  onUnsubscribe: (e, path: string) => void;
  onCopyToCloseout: (
    e: React.MouseEvent<HTMLElement>,
    item: FileExplorerItem
  ) => void;
  onTogglePermissionsModal: (e?, path?: string) => void;
  onToggleShareModal: (path?: string) => void;
  onToggleAdd: (e: React.MouseEvent<HTMLElement>, name?: string) => void;
  onToggleDelete: (e: React.MouseEvent<HTMLElement>, name?: string) => void;
  onToggleFileDelete: (e: React.MouseEvent<HTMLElement>, name?: string) => void;
  onCopyForMoving: (
    e: React.MouseEvent<HTMLElement>,
    item?: FileExplorerItem
  ) => void;
  onItemSelect: (item: FileExplorerItem, isSelected: boolean) => void;
  selectedItems: FileExplorerItem[];
  extendedLockFolders: string[];
  draggableId?: string;
}

const ExplorerItemDiv = styled.div<{ isSelected: boolean }>(
  ({ isSelected }) => ({
    backgroundColor: isSelected ? 'lightgray' : 'white',
  })
);

export const FileExplorerItemLines = ({
  items,
  locked,
  searchView,
  hoveredName,
  addToFolderName,
  editFolderName,
  subPath,
  startingDir,
  extendedLockFolders,
  draggableId,
  onItemSelect,
  selectedItems,
  ...props
}: FileExplorerItemsProps) => {
  const MENU_ID = `file-explorer-menu-${draggableId}`;
  const [isSelectedItemLocked, setIsSelectedItemLocked] = useState(false);
  const [selectedItem, setSelectedItem] = useState<FileExplorerItem>();

  const { show } = useContextMenu({ id: MENU_ID });

  const displayContextMenu = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    isLocked: boolean,
    item: FileExplorerItem
  ) => {
    setIsSelectedItemLocked(isLocked);
    setSelectedItem(item);
    //set state, pass state into
    show({ event: e });
  };

  const SuperUserPmComp = MyAuthElement([
    KnownRoles.Admin,
    KnownRoles.CoreSuperUser,
    KnownRoles.CSGPM,
  ]);

  let itemLines: JSX.Element[] = [];

  if (searchView) {
    let i = 0;
    itemLines = items.map((x, idx) => {
      const j = i;
      const icon = <span className="fas fa-file"></span>;
      const display = x.path.split('/').join(' > ');
      const enter = () => props.onFileHover(x.path, x.name);
      const leave = () => props.onFileHover(undefined);
      const hovered = hoveredName === x.name;
      const click =
        x.name.indexOf('.pdf') === -1
          ? () => props.onToggleCarousel(j)
          : () => props.onOpenFileTab(encodeURI(x.path));

      if (x.name.indexOf('.pdf') === -1) {
        i++;
      }

      return draggableId ? (
        <Draggable
          key={x.name + '_' + draggableId}
          draggableId={x.name + '_' + draggableId}
          index={idx}
        >
          {(provided) => (
            <ExplorerItemDiv
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              key={x.name}
              className="file-explorer-line search-result"
              onClick={click}
              onMouseEnter={enter}
              onMouseLeave={leave}
              id={x.name}
              onDragStart={() => {}}
              onContextMenu={(e) => displayContextMenu(e, false, x)}
              isSelected={selectedItem === x}
            >
              <div>
                {icon}
                <span className="item-name" onClick={click}>
                  {display}
                </span>
                {hovered && (
                  <div className="hover-options">
                    <FileDownloader
                      actionPath="api/FileExplorer/Download"
                      queryParams={{ filePath: encodeURI(x.path) }}
                      fileType="file"
                    />
                  </div>
                )}
              </div>
            </ExplorerItemDiv>
          )}
        </Draggable>
      ) : (
        <ExplorerItemDiv
          key={x.name}
          className="file-explorer-line search-result"
          onClick={click}
          onMouseEnter={enter}
          onMouseLeave={leave}
          onContextMenu={(e) => displayContextMenu(e, false, x)}
          isSelected={selectedItem === x}
        >
          <div>
            {icon}
            <span className="item-name" onClick={click}>
              {display}
            </span>
            {hovered && (
              <div className="hover-options">
                <FileDownloader
                  actionPath="api/FileExplorer/Download"
                  queryParams={{ filePath: encodeURI(x.path) }}
                  fileType="file"
                />
              </div>
            )}
          </div>
        </ExplorerItemDiv>
      );
    });
  } else {
    let i = 0;
    itemLines = items.map((x, idx) => {
      const j = i;
      const isLocked =
          locked ||
          extendedLockFolders.indexOf(x.name) !== -1 ||
          (x.type === ExplorerItemType.Folder &&
            x.permissions !== FolderPermissionLevel.Write) ||
          (x.type === ExplorerItemType.File &&
            extendedLockFolders.some((f) => subPath?.indexOf(f) !== -1)),
        icon =
          x.type === ExplorerItemType.Folder ? (
            <span className="fas fa-folder-open"></span>
          ) : (
            <>
              <span
                style={{
                  display: 'flex',
                  float: 'left',
                  paddingRight: '8px',
                }}
              >
                <input
                  style={{ marginTop: '3px' }}
                  type="checkBox"
                  onClick={(e) => {
                    e.stopPropagation();
                    onItemSelect(x, e.currentTarget.checked);
                  }}
                  checked={selectedItems.includes(x)}
                />
              </span>
              <span className="fas fa-file"></span>
            </>
          ),
        //path = subPath ? subPath + '/' + x.name : x.name,
        click =
          x.type === ExplorerItemType.Folder
            ? () => props.onSearchItems(x.path)
            : x.name.indexOf('.pdf') === -1
            ? () => props.onToggleCarousel(j)
            : () => props.onOpenFileTab(encodeURI(x.path)),
        enter =
          x.type === ExplorerItemType.File
            ? () => props.onFileHover(x.path, x.name)
            : () => props.onFolderHover(x.name),
        leave =
          x.type === ExplorerItemType.File
            ? () => props.onFileHover()
            : () => props.onFolderHover(undefined),
        hovered = hoveredName === x.name,
        adding = addToFolderName === x.name,
        editing = editFolderName === x.name;

      if (x.type === ExplorerItemType.File && x.name.indexOf('.pdf') === -1)
        i++;

      return draggableId ? (
        <Draggable
          key={x.name + '_' + draggableId}
          draggableId={x.name + '_' + draggableId}
          index={idx}
        >
          {(provided) => {
            return (
              <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                key={x.name}
                id={x.name}
              >
                <ExplorerItemDiv
                  className="file-explorer-line"
                  onClick={click}
                  onMouseEnter={enter}
                  onMouseLeave={leave}
                  onContextMenu={(e) => displayContextMenu(e, isLocked, x)}
                  isSelected={selectedItem === x}
                >
                  <div>
                    {icon}
                    {editing ? (
                      <div className="edit-folder-line">
                        <input
                          id="edit-folder-input"
                          defaultValue={x.name}
                          onClick={(e) => e.stopPropagation()}
                        />
                        <button
                          title="Save"
                          className="btn btn-sm btn-blue fas fa-check"
                          onClick={(e) => props.onEditFolder(e)}
                        ></button>
                        <button
                          title="Cancel"
                          className="btn btn-sm btn-outline-secondary fas fa-ban"
                          onClick={(e) => props.onToggleEdit(e, undefined)}
                        ></button>
                      </div>
                    ) : (
                      x.name
                    )}
                  </div>
                  {hovered && (
                    <div className="hover-options">
                      {!isLocked &&
                      x.type === ExplorerItemType.Folder &&
                      x.name !== 'RFIs' &&
                      x.name !== 'Submittals' ? (
                        x.isUserSubscribed ? (
                          <span
                            title="Unsubscribe from this folder"
                            className="fas fa-eye-slash btn"
                            onClick={(e) => props.onUnsubscribe(e, x.path)}
                          ></span>
                        ) : (
                          <span
                            title="Subscribe to this folder"
                            className="fas fa-eye btn"
                            onClick={(e) => props.onSubscribe(e, x.path)}
                          ></span>
                        )
                      ) : (
                        []
                      )}
                      {startingDir.indexOf('/Projects/') !== -1 &&
                        x.path.indexOf('/Closeout') === -1 && (
                          <span
                            title="Copy to closeout folder"
                            className="fas fa-book btn"
                            onClick={(e) => props.onCopyToCloseout(e, x)}
                          ></span>
                        )}
                      {x.type === ExplorerItemType.Folder && (
                        <span
                          title="View folder permissions"
                          className="fas fa-user-friends btn"
                          onClick={(e) =>
                            props.onTogglePermissionsModal(e, x.path)
                          }
                        ></span>
                      )}
                      {x.permissions === FolderPermissionLevel.Write && (
                        <SuperUserPmComp
                          componentType="span"
                          class="fas fa-share-alt btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.onToggleShareModal(x.path);
                          }}
                        ></SuperUserPmComp>
                      )}
                      {!isLocked && x.type === ExplorerItemType.Folder && (
                        <span
                          title="Add subfolder"
                          className="fas fa-plus btn"
                          onClick={(e) => props.onToggleAdd(e, x.name)}
                        ></span>
                      )}
                      {!isLocked && x.type === ExplorerItemType.Folder && (
                        <span
                          title="Edit"
                          className="fas fa-edit btn"
                          onClick={(e) => props.onToggleEdit(e, x.name)}
                        ></span>
                      )}
                      {!isLocked && (
                        <span
                          title="Copy to Move"
                          className="fas fa-copy btn"
                          onClick={(e) => props.onCopyForMoving(e, x)}
                        ></span>
                      )}
                      {x.type === ExplorerItemType.File && (
                        <FileDownloader
                          actionPath="api/FileExplorer/Download"
                          queryParams={{ filePath: encodeURI(x.path) }}
                          fileType=""
                        />
                      )}
                      {x.type === ExplorerItemType.Folder && (
                        <FileDownloader
                          actionPath="api/FileExplorer/Zip"
                          queryParams={{ subPath: encodeURI(x.path) }}
                          fileType="zip"
                        />
                      )}
                      {!isLocked && (
                        <span
                          title="Delete"
                          className="fas fa-trash btn"
                          onClick={
                            x.type === ExplorerItemType.Folder
                              ? (e) => props.onToggleDelete(e, x.name)
                              : (e) => props.onToggleFileDelete(e, x.name)
                          }
                        ></span>
                      )}
                    </div>
                  )}
                </ExplorerItemDiv>
                {adding && (
                  <div className="new-folder-line">
                    <input id="add-folder-input" /> &nbsp;
                    <div title="Inherit the permissions from the parent folder">
                      <span>Inherit permissions?</span>{' '}
                      <input type="checkbox" id="inherit-permissions" />
                    </div>
                    <button
                      title="Save"
                      className="btn btn-sm btn-blue fas fa-check"
                      onClick={props.onAddFolder}
                    ></button>
                    <button
                      title="Cancel"
                      className="btn btn-sm btn-outline-secondary fas fa-ban"
                      onClick={(e) => props.onToggleAdd(e, undefined)}
                    ></button>
                  </div>
                )}
              </div>
            );
          }}
        </Draggable>
      ) : (
        <div key={x.name}>
          <ExplorerItemDiv
            className="file-explorer-line"
            onClick={click}
            onMouseEnter={enter}
            onMouseLeave={leave}
            onContextMenu={(e) => displayContextMenu(e, isLocked, x)}
            isSelected={selectedItem === x}
          >
            <div>
              {icon}
              {editing ? (
                <div className="edit-folder-line">
                  <input
                    id="edit-folder-input"
                    defaultValue={x.name}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <button
                    title="Save"
                    className="btn btn-sm btn-blue fas fa-check"
                    onClick={(e) => props.onEditFolder(e)}
                  ></button>
                  <button
                    title="Cancel"
                    className="btn btn-sm btn-outline-secondary fas fa-ban"
                    onClick={(e) => props.onToggleEdit(e, undefined)}
                  ></button>
                </div>
              ) : (
                x.name
              )}
            </div>
            {hovered && (
              <div className="hover-options">
                {!isLocked &&
                x.type === ExplorerItemType.Folder &&
                x.name !== 'RFIs' &&
                x.name !== 'Submittals' ? (
                  x.isUserSubscribed ? (
                    <span
                      title="Unsubscribe from this folder"
                      className="fas fa-eye-slash btn"
                      onClick={(e) => props.onUnsubscribe(e, x.path)}
                    ></span>
                  ) : (
                    <span
                      title="Subscribe to this folder"
                      className="fas fa-eye btn"
                      onClick={(e) => props.onSubscribe(e, x.path)}
                    ></span>
                  )
                ) : (
                  []
                )}
                {startingDir.indexOf('/Projects/') !== -1 &&
                  x.path.indexOf('/Closeout') === -1 && (
                    <span
                      title="Copy to closeout folder"
                      className="fas fa-book btn"
                      onClick={(e) => props.onCopyToCloseout(e, x)}
                    ></span>
                  )}
                {x.type === ExplorerItemType.Folder && (
                  <span
                    title="View folder permissions"
                    className="fas fa-user-friends btn"
                    onClick={(e) => props.onTogglePermissionsModal(e, x.path)}
                  ></span>
                )}
                {x.permissions === FolderPermissionLevel.Write && (
                  <SuperUserPmComp
                    componentType="span"
                    class="fas fa-share-alt btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      props.onToggleShareModal(x.path);
                    }}
                  ></SuperUserPmComp>
                )}
                {!isLocked && x.type === ExplorerItemType.Folder && (
                  <span
                    title="Add subfolder"
                    className="fas fa-plus btn"
                    onClick={(e) => props.onToggleAdd(e, x.name)}
                  ></span>
                )}
                {!isLocked && x.type === ExplorerItemType.Folder && (
                  <span
                    title="Edit"
                    className="fas fa-edit btn"
                    onClick={(e) => props.onToggleEdit(e, x.name)}
                  ></span>
                )}
                {!isLocked && (
                  <span
                    title="Copy to Move"
                    className="fas fa-copy btn"
                    onClick={(e) => props.onCopyForMoving(e, x)}
                  ></span>
                )}
                {x.type === ExplorerItemType.File && (
                  <FileDownloader
                    actionPath="api/FileExplorer/Download"
                    queryParams={{ filePath: encodeURI(x.path) }}
                    fileType=""
                  />
                )}
                {x.type === ExplorerItemType.Folder && (
                  <FileDownloader
                    actionPath="api/FileExplorer/Zip"
                    queryParams={{ subPath: encodeURI(x.path) }}
                    fileType="zip"
                  />
                )}
                {!isLocked && (
                  <span
                    title="Delete"
                    className="fas fa-trash btn"
                    onClick={
                      x.type === ExplorerItemType.Folder
                        ? (e) => props.onToggleDelete(e, x.name)
                        : (e) => props.onToggleFileDelete(e, x.name)
                    }
                  ></span>
                )}
              </div>
            )}
          </ExplorerItemDiv>
          {adding && (
            <div className="new-folder-line">
              <input id="add-folder-input" /> &nbsp;
              <div title="Inherit the permissions from the parent folder">
                <span>Inherit permissions?</span>{' '}
                <input type="checkbox" id="inherit-permissions" />
              </div>
              <button
                title="Save"
                className="btn btn-sm btn-blue fas fa-check"
                onClick={props.onAddFolder}
              ></button>
              <button
                title="Cancel"
                className="btn btn-sm btn-outline-secondary fas fa-ban"
                onClick={(e) => props.onToggleAdd(e, undefined)}
              ></button>
            </div>
          )}
        </div>
      );
    });
  }
  return (
    <>
      {itemLines}
      <FileExplorerMenu
        menuId={MENU_ID}
        item={selectedItem}
        isItemLocked={isSelectedItemLocked}
        isSearchView={searchView}
        startingDir={startingDir}
        onSubscribe={props.onSubscribe}
        onUnsubscribe={props.onUnsubscribe}
        onCopyToCloseout={props.onCopyToCloseout}
        onTogglePermissionsModal={props.onTogglePermissionsModal}
        onToggleShareModal={props.onToggleShareModal}
        onToggleAdd={props.onToggleAdd}
        onToggleEdit={props.onToggleEdit}
        onToggleDelete={props.onToggleDelete}
        onToggleFileDelete={props.onToggleFileDelete}
        onCopyForMoving={props.onCopyForMoving}
        onMenuHide={() => setSelectedItem(undefined)}
      />
    </>
  );
};
