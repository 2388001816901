//import { fetch, addTask } from 'domain-task';
import { Action, Reducer, ActionCreator } from 'redux';
import { UserInfo } from '../interfaces/interfaces';

export interface Store {
  user?: UserInfo,
  fetching?: boolean,
  authChecked?: boolean
}

type FetchingUserAction = { type: 'FETCHING_USER' };

type GetUserAction = { type: 'GET_USER', info?: UserInfo };

type ClearUserAction = { type: 'CLEAR_USER' };

type FailedAuthAction = { type: 'FAILED_AUTH' };

type KnownAction = FetchingUserAction | GetUserAction | ClearUserAction | FailedAuthAction

export const actionCreators = {
  getUser: (update?: boolean) => async (dispatch, getState) => {
    //alert('In Method')
    if ((!getState().user.user || update) && !getState().user.fetching) {
      dispatch({ type: 'FETCHING_USER' })
      await fetch(`api/User/Get`, { credentials: 'same-origin' })
        .then(res => Promise.all([res.ok, res.json()]))
        .then(([resOk, user]) => {
          if (resOk) dispatch({ type: 'GET_USER', info: user });
          else dispatch({ type: 'FAILED_AUTH' })
        })
    } else {
      console.log('User info populated')
      //await dispatch({ type: 'GET_USER', info: getState().user.user });
    }    
  },
  toggleSidebarPinned: () => async (dispatch, getState) => {
    dispatch({ type: 'GET_USER', info: { ...getState().user.user, isSidebarPinned: !getState().user.user.isSidebarPinned } })
  },
  clearUser: (dispatch) => {
    dispatch({ type: 'CLEAR_USER' })
  }
}

const unloadedUser = { authChecked: false, fetching: false };

//@ts-ignore
export const reducer: Reducer<Store> = (state: Store, incomingAction: Action) => {
  const action = incomingAction as KnownAction
  switch (action.type) {
    case 'FETCHING_USER':
      return { ...state, fetching: true };
    case 'GET_USER':
      return { ...state, user: action.info, authChecked: true, fetching: false };
    case 'CLEAR_USER':
      return { ...state, user: undefined, authChecked: false, fetching: false };
    case 'FAILED_AUTH':
      return { ...state, user: undefined, authChecked: true, fetching: false }
    default:
      return state || unloadedUser;
  }

}